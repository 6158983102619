const types = {
  SET_EXAM_SESSION: "SET_EXAM_SESSION",

  SET_QUETIONS: "SET_QUETIONS",
  SET_CURRENT_QUETION: "SET_CURRENT_QUETION",
  SET_ANSWERS: "SET_ANSWERS",
  ADD_OR_UPDATE_ANSWER: "ADD_OR_UPDATE_ANSWER",

  SUBMIT_ANSWER: "SUBMIT_ANSWER",
  CHANGE_ANSWER: "CHANGE_ANSWER",
  CHANGE_QUESTION: "CHANGE_QUESTION",
  EXAM_SUBMITTED: "EXAM_SUBMITTED",
  CLEAN_MARKS: "CLEAN_MARKS",
  TEST_SUBMITTED: "TEST_SUBMITTED",
  ATTEMPT_CHEAT: "ATTEMPT_CHEAT",
  LOAD_SINGLE_APPLICATION: "LOAD_SINGLE_APPLICATION",
  CHANGE_SESSION_STATUS: "CHANGE_SESSION_STATUS",
  SET_RESULTS: "SET_RESULTS",
};

export default types;
