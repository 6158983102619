import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { connect } from "react-redux";
import logoLG from "../assets/logo-lg.png";
import logoSM from "../assets/logo-sm.png";
import defaultProfileImage from "../assets/default-profile.jpg";
import { logout } from "../../store/comm/actions";
import colors from "../utils/colors";
import { isEmpty } from "lodash";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuBar from "./MenuBar";
import { Logout } from "@mui/icons-material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ChangePassword from "../applicant/profile/components/ChangePassword";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Header = (props) => {
  const {
    user,
    loading,
    drawerState,
    setDrawerState,
    setShowLoginDialog,
    setShowRegisterDialog,
    logout,
    children,
  } = props;

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [changePassword, setChangePassword] = useState(false);

  return (
    <header role="banner">
      {children}
      <Box sx={{ flexGrow: 1 }} id="checking">
        <AppBar position="static" elevation={0} className="app-bar">
          <Toolbar className="px-3 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {!isEmpty(user) && (
                <button
                  className="mr-3 rounded-circle border-0 p-1 drawer-btn"
                  onClick={setDrawerState}
                  disabled={loading}
                  aria-label={`${drawerState ? "Close" : "Open"} menu drawer`}
                >
                  {drawerState ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
                </button>
              )}
              <img
                style={{ width: "145px" }}
                src={logoLG}
                className="mr-2 d-none d-md-inline"
                alt="MIFOTRA Full Logo"
              />
              <img
                style={{ width: "50px" }}
                src={logoSM}
                className="mr-2 d-inline d-md-none"
                alt="MIFOTRA Small Logo"
              />
            </div>
            <Typography
              component="h1"
              style={{ color: colors.blueLogo }}
              className="font-weight-bold d-none d-md-block"
              id="header-title"
            >
              E-RECRUITMENT
            </Typography>

            {!isEmpty(user) ? (
              <>
                <div className="d-flex align-items-center">
                  <div className="text-primary d-flex">
                    <strong className="d-flex align-items-center">
                      <span
                        style={{ borderRadius: "0px" }}
                        className="role text-truncate badge badge-pill text-blue-main border"
                        aria-label={`User role: ${user.lastName} ${user.firstName}`}
                      >
                        {user.lastName} {user.firstName}
                      </span>
                    </strong>
                    <ArrowLeftIcon />
                  </div>
                  <Avatar
                    id="account-menu-button"
                    alt={`${user.lastName} ${user.firstName} profile picture`}
                    src={`${
                      !!user.profileImage
                        ? "data:image/png;base64," + user.profileImage
                        : defaultProfileImage
                    } `}
                    className={`cursor-pointer`}
                    onClick={(e) => !loading && handleClick(e)}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  />
                  <Menu
                    id="account-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.8,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    aria-label="Account menu"
                    MenuListProps={{
                      "aria-labelledby": "account-menu-button",
                    }}
                    aria-hidden={!open}
                  >
                    <MenuItem disabled className="text-uppercase">
                      {user.lastName} {user.firstName}
                    </MenuItem>
                    <Divider />
                    <Link
                      to="/applicant/profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                      </MenuItem>
                    </Link>
                    <Divider />
                    <MenuItem onClick={() => setChangePassword(true)}>
                      <ListItemIcon>
                        <LockOpenIcon fontSize="small" />
                      </ListItemIcon>
                      Change password
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => logout(history)}
                      className="text-danger"
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" className="text-danger" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center">
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  onClick={() => setShowLoginDialog(true)}
                  className="mr-1"
                  disabled={loading}
                  // aria-label="Open login"
                >
                  Login
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setShowRegisterDialog(true)}
                  disabled={loading}
                  // aria-label="Open register dialog"
                >
                  Register
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>

        {!isEmpty(user) && <MenuBar />}
      </Box>

      {changePassword && (
        <ChangePassword
          onShow={changePassword}
          onClose={() => setChangePassword(false)}
        />
      )}
    </header>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, {
  logout,
})(Header);
