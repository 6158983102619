import COM_TYPES from "./action-types";
import APP_TYPES from "../applicant/action-types";

import axios from "../../app/utils/axios-middleware";
import { showSuccess, showError } from "../../app/toastify";

const types = { ...COM_TYPES, ...APP_TYPES };

export const getEnv = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_ENV, data: null });

      const { data } = await axios.get("/api/env");

      dispatch({ type: types.SET_ENV, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const login = (credentials, history, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/logins", credentials);

      try {
        const { data } = await axios.get("/api/auth/me");

        dispatch({
          type: types.SET_USER,
          data: data,
        });

        dispatch({ type: types.SET_ADVERTISEMENTS, data: [] });

        onClose();

        history.push("/applicant");
      } catch (error) {}

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const logout = (history, pathname = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/auth/logins");

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });

      if (!pathname?.includes("reset-password")) history.push("/");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      history.push("/");
    }
  };
};

export const getUser = (history, path) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/auth/me");

      dispatch({ type: types.SET_USER, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });
      if (!path || !path.includes("reset-password")) history.push("/");
      await axios.delete("/api/auth/logins");
    }
  };
};

export const resetPassword = (email, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/reset-password", { email });

      dispatch({ type: types.END_LOADING });
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
      callback(false);
    }
  };
};

export const changePassword = (credentials, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/change-password", credentials);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Password changed successfully");

      !!onClose && onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const changePasswordNew = (new_password, callback) => {
  callback(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/auth/change-password`, {
        password: new_password,
      });

      const res = await axios.get("/api/auth/me");

      dispatch({ type: types.SET_USER, data: res.data });

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(false, true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
      callback(false, false);
    }
  };
};

export const register = (user, setLoading, setShowDialog, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      setLoading(true);

      await axios.post("/api/auth/users", user);

      const { data } = await axios.get("/api/auth/me");

      setLoading(false);

      dispatch({
        type: types.SET_USER,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Resistered successfully");

      setShowDialog(false);

      history.push("/applicant/profile");
    } catch (error) {
      setLoading(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};


export const getTime = (callback) => {
  return async (dispatch) => {
    callback(true,null)
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/time");

      dispatch({ type: types.END_LOADING });
      callback(true,data.serverTime);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
      callback(false,null);
    }
  };
};