import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CountDownDisplay from "./CountDownDisplay";
import { defaultRemainingTime } from "./DoExam";
import { addZero } from "../../../utils";

const CountDownTimer = (props) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
  const [loading, setLoading] = useState(true);
  let seconds = 0;

  const isTimeOut =
    remainingTime.days === "00" &&
    remainingTime.hours === "00" &&
    remainingTime.minutes === "00" &&
    remainingTime.seconds === "00"
      ? true
      : false;

  useEffect(() => {
    const timer = setInterval(() => {
      updateRemainingTime(seconds);
      loading && setLoading(false);
      seconds += 1000;

      if (seconds >= props.defaultLeftDifferent) {
        clearInterval(timer);
        props.onTimeOut && props.onTimeOut();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const updateRemainingTime = (seconds) => {
    const difference = props.defaultLeftDifferent - seconds;

    if (difference > 0) {
      setRemainingTime({
        days: addZero(Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: addZero(Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: addZero(Math.floor((difference / 1000 / 60) % 60)),
        seconds: addZero(Math.floor((difference / 1000) % 60)),
      });
    } else {
      setRemainingTime(defaultRemainingTime);
    }
  };

  if (loading) return null;

  return (
    <div className={`${props.color === "red" ? "text-danger" : ""}`}>
      {props.children && isTimeOut ? (
        props.children
      ) : (
        <div>
          <span className="sr-only"></span>
          <CountDownDisplay
            time={remainingTime}
            type={props.counterType ?? "default"}
            examLocation={props.examLocation}
            examDate={props.examDate}
          />
        </div>
      )}
    </div>
  );
};

CountDownTimer.propTypes = {
  defaultLeftDifferent: PropTypes.number,
  color: PropTypes.string,
  examLocation: PropTypes.string,
  counterType: PropTypes.string,
};

export default CountDownTimer;
