import AdvertSkeleton from "../../comm/AdvertSkeleton";
import { getRequisitionCompetencies } from "../../../store/applicant/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
const AdvertisementCompetencies = (props) => {
  const {
    loading,
    advertisement,
    requisitionCompetencies,
    getRequisitionCompetencies,
  } = props;

  useEffect(() => {
    getRequisitionCompetencies(advertisement.requisitionId);
  }, []);

  return (
    <>
      {loading && !requisitionCompetencies.length && <AdvertSkeleton />}
      {!!requisitionCompetencies.length && (
        <div className="mt-3">
          <strong>Required competencies and key technical skills</strong>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {requisitionCompetencies.map((competency, index) => (
              <CompetencItem
                key={competency.id}
                index={index}
                competency={competency}
                showDivider={requisitionCompetencies.length > index + 1}
              />
            ))}
          </List>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, requisitionCompetencies }) => ({
  user,
  loading,
  requisitionCompetencies,
});

export default connect(mapStateToProps, { getRequisitionCompetencies })(
  AdvertisementCompetencies
);

const CompetencItem = (props) => {
  const { index, competency, showDivider } = props;

  return (
    <>
      <ListItem dense>
        <ListItemAvatar>
          <Avatar
            style={{
              fontSize: "16px",
              border: "1px solid #c8c6c6",
            }}
            className="bg-white text-dark "
          >
            {index + 1}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={
            <span className="font-weight-light">
              {competency?.competency?.name}
            </span>
          }
        />
      </ListItem>

      {showDivider && <Divider />}
    </>
  );
};
