import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { respondToJobOffer } from "../../../store/applicant/actions";
import axiosMiddleware from "../../utils/axios-middleware";
import NoResults from "../../comm/NoResults";
import { isEmpty } from "lodash";
import moment from "moment";
import ConfirmationDialog from "../../comm/ConfirmationDialog";

const JobOfferDialog = (props) => {
  const { showDialog, setShowDialog, application, respondToJobOffer } = props;

  const [loading, setLoading] = useState(false);

  const [offer, setOffer] = useState({});
  const [confirmJobOffer, setConfirmJobOffer] = useState(false);
  const [statusId, setStatusId] = useState(2);
  const [reason, setReason] = useState("");

  useEffect(() => {
    getJobOfferDetails();
  }, []);

  const getJobOfferDetails = async () => {
    try {
      setLoading(true);
      const { data } = await axiosMiddleware.get(
        `/api/recruitment/job-offer-details/${application.id}`
      );

      setOffer(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">
              {offer.waitingListRequestId ? "Waiting List" : ""} Job offer
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="pb-1">
          <div className="table-responsive rounded border ">
            {loading && isEmpty(offer) ? (
              <Skeleton variant="rectangular" width="100%" height={150} />
            ) : !isEmpty(offer) ? (
              <table className={`table table-sm table-striped mb-0`}>
                <tbody>
                  <tr>
                    <th className="text-center text-uppercase" colSpan={2}>
                      {offer.waitingListRequestId ? "Waiting List" : ""} Job
                      offer
                    </th>
                  </tr>
                  <tr>
                    <th className="text-right py-1">Institution</th>
                    <td>
                      <strong>
                        {offer.placementEntity || application.entityName} -{" "}
                        {offer.placementSector || application.sectorName} sector
                      </strong>

                      {offer.teacherPlacement && (
                        <span className="font-weight-bold d-flex flex-wrap  py-1 align-items-center text-left badge badge-primary">
                          <i className="fas fa-map-marker-alt mr-1"></i>{" "}
                          {offer.teacherPlacement}
                        </span>
                      )}
                    </td>
                  </tr>
                  {!!offer.createdOn && (
                    <tr>
                      <th className="text-right py-1">OfferedOn</th>
                      <td>{moment(offer.createdOn).format("lll")}</td>
                    </tr>
                  )}

                  {!!offer.expiredOn && (
                    <tr>
                      <th className="text-right py-1">ExpireOn</th>
                      <td>{moment(offer.expiredOn).format("lll")}</td>
                    </tr>
                  )}

                  {!!offer.revokedOn && (
                    <tr>
                      <th className="text-right py-1">ExpireOn</th>
                      <td>{moment(offer.revokedOn).format("lll")}</td>
                    </tr>
                  )}

                  <tr>
                    <th className="text-right py-1">Employment status</th>
                    <td>
                      {offer.employeeGroupName || application.employeeGroupName}
                    </td>
                  </tr>

                  <tr>
                    <th className="text-right py-1">Job offer status</th>
                    <td>
                      <span
                        className={`badge p-1 badge-${
                          [2, 5].includes(application.jobOfferStatusId)
                            ? "success"
                            : [3, 4].includes(application.jobOfferStatusId)
                            ? "danger"
                            : application.jobOfferStatusName === "Offer expired"
                            ? "warning"
                            : "secondary"
                        }`}
                      >
                        {offer.statusName}
                      </span>
                    </td>
                  </tr>
                  {!!offer.rejectionReason && (
                    <tr>
                      <th className="text-right py-1">RejectionReason</th>
                      <td>{offer.rejectionReason}</td>
                    </tr>
                  )}

                  {!!offer.revokedReason && (
                    <tr>
                      <th className="text-right py-1">RevokedReason</th>
                      <td>{offer.revokedReason}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <NoResults />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3">
          {offer.statusId === 1 && (
            <Button
              disabled={loading || confirmJobOffer}
              onClick={() => {
                setStatusId(2);
                setConfirmJobOffer(true);
              }}
              variant="contained"
              color="success"
              size="small"
              aria-label={`Accept job offer from ${
                offer.placementEntity || application.entityName
              } - ${offer.placementSector || application.sectorName} sector`}
            >
              <span aria-hidden="true">Accept</span>
            </Button>
          )}
          {(offer.statusId === 1 || offer.statusId === 2) && (
            <Button
              disabled={loading || confirmJobOffer}
              onClick={() => {
                setStatusId(3);
                setConfirmJobOffer(true);
              }}
              variant="contained"
              color="error"
              size="small"
              aria-label={`Reject job offer from ${
                offer.placementEntity || application.entityName
              } - ${offer.placementSector || application.sectorName} sector`}
            >
              <span aria-hidden="true">Reject</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {confirmJobOffer && (
        <ConfirmationDialog
          color={`${statusId === 2 ? "success" : "error"}`}
          disabled={statusId === 3 && !reason}
          btnMessage={`${statusId === 2 ? "accept" : "reject"}`}
          confirmationDialog={confirmJobOffer}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong
                  className={`mx-1 text-${
                    statusId === 2 ? "success" : "danger"
                  }`}
                >
                  {statusId === 2 ? "accept" : "reject"}
                </strong>{" "}
                this job offer?
              </p>

              {statusId === 3 && (
                <TextField
                  fullWidth
                  label="Reason for rejection"
                  placeholder="Reason for rejection"
                  value={reason || ""}
                  size="small"
                  multiline
                  minRows={4}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              )}
            </>
          }
          setConfirmationDialog={setConfirmJobOffer}
          onYes={() => {
            respondToJobOffer(
              {
                id: offer.id,
                statusId,
                reason,
              },
              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { respondToJobOffer })(JobOfferDialog);
