import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function AlertDialog(props) {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={props.status}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="text-danger"
        >
          {props.desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleClose}
          disabled={props.disabled}
          aria-label="Cancel"
        >
          <span aria-hidden="true">No</span>
        </Button>
        <Button
          onClick={props.handleConfirm}
          disabled={props.disabled}
          autoFocus
          aria-label="confirm"
        >
          <span aria-hidden="true">Yes</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
