import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="text-center pt-2"
      style={{
        backgroundColor: "#f6f8fa",
        borderTop: "1px solid rgba(0, 0, 0, 0.125)",
      }}
      role="contentinfo"
    >
      <span className="text-uppercase" style={{ fontSize: "12px" }}>
        &copy;{new Date().getFullYear()}{" "}
        <a
          href="https://mifotra.gov.rw/"
          target="_blank"
          rel="noreferrer"
          className="text-dark"
        >
          Ministry of Public Service and Labour (MIFOTRA)
        </a>
      </span>

      <div style={{ fontSize: "10px" }}>
        <a
          className="my-sm-0 text-uppercase text-blue-100"
          rel="noreferrer"
          href="http://recruitment.mifotra.gov.rw/"
          target="_blank"
        >
          E-Recruitment
        </a>{" "}
        <span aria-hidden="true">|</span>{" "}
        <a
          className="my-sm-0 text-uppercase text-blue-100"
          rel="noreferrer"
          href="https://ippis.rw/"
          target="_blank"
        >
          IPPIS
        </a>{" "}
        <span aria-hidden="true">|</span>{" "}
        <Link className="my-sm-0 text-uppercase text-blue-100" to="#">
          About IPPIS
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
