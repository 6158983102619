export const defaultState = {
  advertisements: [],
  requisitionQualifications: [],
  requisitionCertificates: [],
  requisitionCompetencies: [],
  applications: [],
  waitingList: [],
  waitingListDetails: [],
  selectedAdvertisement: null,
  appeal: {},
  announcements:[]
};
