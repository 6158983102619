import { useEffect } from "react";

const eventToRestrict = ["copy", "cut", "paste", "contextmenu"];
export const useRestrictEvents = () => {
  useEffect(() => {
    eventToRestrict.forEach((action) => {
      window.addEventListener(action, preventPaste);
    });

    return () => {
      eventToRestrict.forEach((action) => {
        window.removeEventListener(action, preventPaste);
      });
    };
  }, [window, eventToRestrict]);

  const preventPaste = (e) => {
    e.preventDefault();
  };
};

export const ctrlShiftKey = (e, keyCode) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};
