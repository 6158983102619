import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
// import DialogModel from "../../common/components/DialogModel";
import { login, resetPassword } from "../../../store/comm/actions";
import { useHistory } from "react-router-dom";

function LoginModal(props) {
  const {
    loading,
    showDialog,
    setShowDialog,
    setShowRegisterDialog,
    login,
    resetPassword,
  } = props;

  const [forgotPassword, setForgotPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
    ipAddress: "",
  });
  const [formError, setFormError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getClientIp();
  }, []);

  const getClientIp = async () => {
    try {
      const { data } = await axios.get(`https://api.ipify.org`);
      setCredentials({
        ...credentials,
        ipAddress: data,
      });
    } catch (error) {}
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (credentials.email.length === 0) {
      setFormError({ type: "error", msg: "Username is required!" });
      return;
    }
    if (credentials.password.length === 0) {
      setFormError({ type: "error", msg: "Password is required!" });
      return;
    }

    login(
      {
        username: credentials.email,
        password: credentials.password,
        ipAddress: credentials.ipAddress,
      },
      history,
      onClose
    );
  };

  const handleReset = () => {
    if (credentials.email.length === 0) {
      setFormError({ type: "error", msg: "Email is required!" });
      return;
    }
    resetPassword(credentials.email, (data) => {
      if (data === true) {
        setForgotPassword(false);
        setFormError({
          type: "success",
          msg: "Please check your email, for reset token!",
        });
      }
    });
  };

  const onClose = () => {
    setShowDialog(false);

    // if (!loading) {
    //   setForgotPassword(false);
    //   setCredentials({
    //     email: "",
    //     password: "",
    //     showPassword: false,
    //   });
    //   setFormError(null);
    //   showLoginModel(false, "");
    // }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {forgotPassword ? "Reset password" : "Login to e-recruitment"}
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <div
          style={{
            minHeight: "40vh",
          }}
        >
          <div className="mx-0 px-0 text-center mt-2">
            {!forgotPassword && (
              <form onSubmit={onLogin}>
                {formError && (
                  <Alert severity={formError.type} className="mb-3">
                    {formError.msg}
                  </Alert>
                )}

                <TextField
                  size="small"
                  label="Enter your email"
                  placeholder="Email"
                  value={credentials.email}
                  onChange={(e) => {
                    const email = e.target.value;
                    setCredentials({ ...credentials, email });
                    setFormError(null);
                  }}
                  disabled={loading}
                  fullWidth
                  className="mb-3"
                  type="email"
                  autoFocus
                />

                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="mt-2"
                >
                  <InputLabel htmlFor="password">
                    Enter your password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    placeholder="Password"
                    name="password"
                    type={credentials.showPassword ? "text" : "password"}
                    value={credentials.password}
                    onChange={(e) => {
                      const password = e.target.value;
                      setCredentials({ ...credentials, password });
                      setFormError(null);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label={
                            credentials.showPassword
                              ? "Hide password"
                              : "Show password"
                          }
                          onClick={() =>
                            setCredentials({
                              ...credentials,
                              showPassword: !credentials.showPassword,
                            })
                          }
                          edge="end"
                        >
                          {credentials.showPassword ? (
                            <span className="material-icons">
                              visibility_off
                            </span>
                          ) : (
                            <span className="material-icons">visibility</span>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Enter your password"
                    disabled={loading}
                  />
                </FormControl>

                {!loading && (
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    disableElevation
                    className="btn-block mt-3"
                  >
                    Login
                  </Button>
                )}

                {loading && (
                  <Button
                    type="button"
                    variant="contained"
                    disableElevation
                    className="btn-block text-light mt-3"
                  >
                    Connecting...
                  </Button>
                )}

                <div className="text-right mt-2">
                  <span
                    onClick={() => !loading && setForgotPassword(true)}
                    className="cursor-pointer my-1 text-primary"
                    style={{ fontSize: "14px" }}
                    role="link"
                    tabIndex={0}
                  >
                    Reset password?
                  </span>
                </div>

                <p
                  className="mt-4 mb-3 text-muted"
                  style={{ fontSize: "14px" }}
                >
                  If you don't have an account, <br />
                  Please,{" "}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      setShowRegisterDialog(true);
                      setShowDialog(false);
                    }}
                    aria-label="register"
                    role="button"
                  >
                    Register
                  </span>
                </p>
              </form>
            )}

            {forgotPassword && (
              <>
                {formError && (
                  <Alert severity={formError.type} className="mb-3">
                    {formError.msg}
                  </Alert>
                )}

                <TextField
                  size="small"
                  fullWidth
                  autoFocus
                  variant="outlined"
                  label="Enter your email"
                  placeholder="Email"
                  type="email"
                  value={credentials.email}
                  className="mb-3"
                  onChange={(e) =>
                    setCredentials({ ...credentials, email: e.target.value })
                  }
                  disabled={loading}
                />

                <Button
                  variant="contained"
                  disableElevation
                  className="btn btn-block"
                  type="button"
                  onClick={handleReset}
                  disabled={loading}
                >
                  Reset password
                </Button>

                <Button
                  onClick={() => setForgotPassword(false)}
                  className="pr-0 mt-3"
                  disabled={loading}
                >
                  <span className="material-icons">
                    keyboard_double_arrow_left
                  </span>{" "}
                  Back to Login
                </Button>

                <small className="mt-4 text-muted d-block">
                  If you don't remember your email. <br />
                  Please, contact IPPIS Support for help. <br />
                  Tel: 9997 or 0785569363
                </small>
              </>
            )}
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button variant="contained" type="button">
          Apply now
        </Button>
      </DialogActions> */}
    </Dialog>

    // <DialogModel
    //   title={forgotPassword ? "Reset password" : "Login to e-recruitment"}
    //   isOpen={showDialog}
    //   onClose={onClose}
    //   maxWidth="xs"
    //   hideFooter={true}
    // >

    // </DialogModel>
  );
}

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};

export default connect(mapStateToProps, {
  login,
  resetPassword,
})(LoginModal);
