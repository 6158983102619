import React from "react";
import PropTypes from "prop-types";

const ExamFileViewer = (props) => {
  const { fileUrl } = props;
  return (
    <embed
      src={fileUrl + "#toolbar=0"}
      type="application/pdf"
      height="100%"
      width="100%"
    />
  );
};

ExamFileViewer.propTypes = {
  fileUrl: PropTypes.string,
};

export default ExamFileViewer;
