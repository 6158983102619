const types = {
  SET_ADVERTISEMENTS: "SET_ADVERTISEMENTS",
  UPDATE_ADVERTISEMENT: "UPDATE_ADVERTISEMENT",
  DELETE_ADVERTISEMENT: "DELETE_ADVERTISEMENT",

  SET_REQUISITION_QUALIFICATIONS: "SET_REQUISITION_QUALIFICATIONS",
  UPDATE_REQUISITION_QUALIFICATION: "UPDATE_REQUISITION_QUALIFICATION",

  SET_REQUISITION_CERTIFICATES: "SET_REQUISITION_CERTIFICATES",
  UPDATE_REQUISITION_CERTIFICATE: "UPDATE_REQUISITION_CERTIFICATE",

  SET_REQUISITION_COMPETENCIES: "SET_REQUISITION_COMPETENCIES",

  SET_APPLICATIONS: "SET_APPLICATIONS",
  UPDATE_APPLICATION: "UPDATE_APPLICATION",
  DELETE_APPLICATION: "DELETE_APPLICATION",

  SET_WAITING_LIST: "SET_WAITING_LIST",
  SET_WAITING_LIST_DETAILS: "SET_WAITING_LIST_DETAILS",
  SET_SELECTED_ADVERTISEMENT: "SET_SELECTED_ADVERTISEMENT",
  SET_APPEAL: "SET_APPEAL",
  SET_ANNOUNCEMENTS:"SET_ANNOUNCEMENTS",
  DELETE_ANNOUNCEMENT:"DELETE_ANNOUNCEMENT"
};

export default types;
