import { Dialog, Box, CircularProgress, DialogContent } from "@mui/material";

const ActionDialog = (props) => {
  const { showAction, action } = props;

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showAction}
        maxWidth="sm"
      >
        <DialogContent>
          <div>
            <Box className="justify-content-center " sx={{ display: "flex" }}>
              <CircularProgress />
              <h5 className="mt-2 ml-2">{action}</h5>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActionDialog;
