import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { submitAppeal } from "../../../store/applicant/actions";

const AppealForm = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    application,
    appealLevelId,
    appealTypeId,
    submitAppeal,
    onClose: onCloseParent,
    appealIdL1,
  } = props;

  const [yesIAgree, setYesIAgree] = useState(false);
  const [appealReason, setAppealReason] = useState("");
  const [confirmDeclaration, setConfirmDeclaration] = useState(false);

  const onClose = () => {
    setShowDialog(false);
    !!onCloseParent && onCloseParent();
  };

  const handleSubmit = () => {
    submitAppeal(
      {
        applicationId: application.id,
        advertisementId: application.advertisementId,
        appealTypeId: appealTypeId,
        message: appealReason,
        levelId: appealLevelId,
        appealIdL1: appealIdL1,
      },
      onClose
    );
  };

  return (
    <Dialog
      onClose={() => setShowDialog(false)}
      open={showDialog}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Typography variant="overline" display="block">
          <span className="text-dark">
            Appeal for {application.positionName}
          </span>
        </Typography>
        <IconButton
          aria-label="close appeal form"
          onClick={() => setShowDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <span className="material-icons" aria-hidden="true">
            close
          </span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div
          style={{
            minHeight: "45vh",
          }}
        >
          <div>
            Before you make an appeal, are you aware that your meaningless
            appeal will affect your application?
            <div
              className="btn px-0 d-flex flex-row align-items-center max-w-content mr-1 mt-1"
              style={{ fontSize: "16px" }}
            >
              <span className="font-weight-bold" aria-hidden="true">
                Yes, I am aware
              </span>
              <Checkbox
                checked={yesIAgree}
                onChange={() => setYesIAgree(!yesIAgree)}
                inputProps={{
                  "aria-label": "Yes, I am aware",
                }}
              />
            </div>
          </div>

          {yesIAgree && (
            <div className="mt-4">
              <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-1">
                <span className="font-weight-bold">
                  What is the reason for your appeal?
                </span>
                <span className="text-secondary" style={{ fontSize: "14px" }}>
                  ( * Please do not exceed 500 characters )
                </span>
              </div>
              <TextField
                className="mt-2"
                fullWidth
                multiline
                rows={6}
                size="small"
                name="message"
                label={`Enter your appeal`}
                variant="outlined"
                value={appealReason}
                inputProps={{
                  maxLength: 500,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setAppealReason(e.target.value);
                }}
              />
            </div>
          )}

          {yesIAgree && (
            <div className="mt-4">
              <span className="font-weight-bold">
                Declaration of the appeal
              </span>
              <div>
                <span>
                  I{" "}
                  <span className="text-primary">
                    {props.user.firstName + " " + props.user.lastName}
                  </span>
                  ,
                </span>

                <p>
                  Hereby declare that all the details mentioned in this appeal
                  are in accordance with the truth and fact as per my knowledge
                  and I truly understand that any abuse of the system shall
                  affect my job application.
                </p>

                <div
                  className="btn px-0 d-flex flex-row align-items-center cursor-pointer max-w-content mt-n2"
                  style={{ fontSize: "16px" }}
                  onClick={() => {
                    setConfirmDeclaration(!confirmDeclaration);
                  }}
                >
                  <Checkbox
                    checked={confirmDeclaration}
                    onChange={() => setConfirmDeclaration(!confirmDeclaration)}
                    inputProps={{
                      "aria-label": "Yes, I confirm my declaration",
                    }}
                  />
                  <span className="font-weight-bold ml-1" aria-hidden="true">
                    Please, confirm your declaration.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={
            loading || !yesIAgree || !confirmDeclaration || !appealReason
          }
          onClick={handleSubmit}
          aria-label="Submit your appeal"
        >
          {loading ? "Wait..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading }) => ({
  user,
  loading,
});

export default connect(mapStateToProps, { submitAppeal })(AppealForm);
