export const sortConditionByIdDesc = (a, b) => {
  if (a.id > b.id) {
    return -1;
  }
  if (a.id < b.id) {
    return 1;
  }
  return 0;
};

export const shuffle = (array) => {
  array.reverse().forEach((item, index) => {
    const j = Math.floor(Math.random() * (index + 1));
    [array[index], array[j]] = [array[j], array[index]];
  });
  return array;
};

export const shuffleQuestions = (questions) => {
  let result = [];
  const shuffledQuestions = [...shuffle(questions)];
  shuffledQuestions.forEach((element) => {
    let thisQuestion = element;
    thisQuestion.question.multipleChoiceOptions = [
      ...shuffle(element?.question?.multipleChoiceOptions),
    ];

    if (!!thisQuestion) result.push(thisQuestion);
  });

  return result;
};

export const sortQuestionByReference = (questions) => {
  let result = questions;
  (questions || []).forEach((questionItem, index) => {
    if (!!+questionItem?.question?.referenceQuestionNo) {
      // find the referenced question
      const parentIndex = result.findIndex(
        (item) =>
          !!item &&
          item?.questionNo === questionItem?.question?.referenceQuestionNo
      );
      if (parentIndex >= 0) {
        // get the index that can fit the swap
        const swapIndex = getAvailableIndex(
          result,
          questionItem?.question?.referenceQuestionNo,
          parentIndex
        );
        const swapQuestion = result[swapIndex];

        if (!!swapQuestion) {
          result[swapIndex] = questionItem;
          result[index] = swapQuestion;
        }
      }
    }
  });

  return result;
};

const getAvailableIndex = (questions, questionNo, parentIndex) => {
  let index = parentIndex + 1;
  while (index < questions?.length) {
    if (questions[index]?.question?.referenceQuestionNo !== questionNo) break;
    else index++;
  }

  return index;
};

export const sortApplicationByExamDesc = (applications) => {
  let result = applications;
  applications.forEach((applicationItem, index) => {
    if (index + 1 !== applications.length && index !== 0) {
      if (
        applicationItem.isOralExamScheduled &&
        !applicationItem.isOralExamEnded &&
        (!result[index - 1].isOralExamScheduled ||
          !!result[index - 1].isOralExamEnded ||
          (result[index - 1].oralTimeLeftMinutes &&
            applicationItem.oralTimeLeftMinutes &&
            result[index - 1].oralTimeLeftMinutes >
              applicationItem.oralTimeLeftMinutes &&
            !result[index - 1].isOralExamEnded))
      ) {
        const swap = result[index - 1];
        result[index - 1] = applicationItem;
        result[index] = swap;
      } else if (
        applicationItem.isExamScheduled &&
        !applicationItem.isExamEnded &&
        (!result[index - 1].isExamScheduled ||
          !!result[index - 1].isExamEnded ||
          (result[index - 1].examStartTimeLeftMinutes &&
            applicationItem.examStartTimeLeftMinutes &&
            result[index - 1].examStartTimeLeftMinutes >
              applicationItem.examStartTimeLeftMinutes &&
            !result[index - 1].isExamEnded))
      ) {
        const swap = result[index - 1];
        result[index - 1] = applicationItem;
        result[index] = swap;
      }
    }
  });

  return result;
};
