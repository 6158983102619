import React, { useState, useEffect } from "react";
import { addOrUpdateUserCertificate } from "../../../../store/profile/actions";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
// import { getSchoolYears } from "../../../utils";

const getSchoolYears = (currentDate = new Date()) => {
  const d = new Date(currentDate);
  const currentYear = d.getFullYear();
  let i = 0;
  let result = [];
  while (i <= 50) {
    result.push({ id: i + 1, name: (+currentYear - i).toString() });
    i++;
  }
  return result;
};

const CertificateForm = (props) => {
  const {
    loading,
    selectedCertificate,
    onShow,
    onClose,
    addOrUpdateUserCertificate,
    lookup,
  } = props;

  const [certificate, setCertificate] = useState(null);
  const [year, setYear] = useState(null);
  const [institution, setInstitution] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    setCertificate(selectedCertificate?.certificate || null);
    setYear(
      getSchoolYears().find(
        ({ name }) => name === selectedCertificate?.yearIssued
      ) || null
    );
    setInstitution(selectedCertificate?.institutionName || "");
  }, []);

  const [errors, setErrors] = useState({
    certificateHasError: false,
    yearHasError: false,
    institutionHasError: false,
    fileHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      certificateHasError: false,
      yearHasError: false,
      institutionHasError: false,
      fileHasError: false,
      hasError: false,
    };

    if (!certificate) {
      error.certificateHasError = true;
      error.hasError = true;
    }

    if (!year) {
      error.yearHasError = true;
      error.hasError = true;
    }

    if (!institution) {
      error.institutionHasError = true;
      error.hasError = true;
    }

    if (!file) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!formValidator()) {
      const formData = new FormData();
      formData.append("id", selectedCertificate?.id || "");
      formData.append("certificateId", certificate.id);
      formData.append("yearIssued", year.name);
      formData.append("institutionName", institution);
      file && formData.append("file", file[0]);

      addOrUpdateUserCertificate(formData, onClose);
    }
  };

  return (
    <Dialog
      // onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedCertificate ? "Add " : "Update "}
            award/certificate
          </span>
        </Typography>
        <IconButton
          aria-label="close certificate form"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-md-4 mt-2">
          <form>
            <div className="form-group mt-3">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={certificate}
                options={lookup.certificates || []}
                disabled={loading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, certificate) => {
                  setCertificate(certificate || null);
                  setErrors({ ...errors, certificateHasError: false });
                }}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, certificate) => (
                  <Box component="li" {...props} key={certificate.id}>
                    {certificate.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "25px !important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px !important",
                    }}
                    {...params}
                    label="Select certificate"
                    placeholder="Select certificate"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                    error={errors.certificateHasError}
                  />
                )}
              />
              {errors.certificateHasError && (
                <small className="text-danger">Certificate is required</small>
              )}
            </div>

            <div className="form-group mt-3">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={year}
                options={getSchoolYears()}
                disabled={loading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, year) => {
                  setYear(year || null);
                  setErrors({ ...errors, yearHasError: false });
                }}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, year) => (
                  <Box component="li" {...props} key={year.id}>
                    {year.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "25px !important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px !important",
                    }}
                    {...params}
                    
                    label="Issued in year"
                    placeholder="Issued in year"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                    error={errors.yearHasError}
                  />
                )}
              />
              {errors.yearHasError && (
                <small className="text-danger">Year is required</small>
              )}
            </div>

            <div className="mt-3">
              <TextField
                fullWidth
                label="Institution"
                placeholder="Institution"
                id="employee-names"
                value={institution}
                disabled={loading}
                inputProps={{
                  autoComplete: "off", // disable autocomplete and autofill
                }}
                size="small"
                onChange={(e) => {
                  setInstitution(e.target.value || "");
                  setErrors({ ...errors, institutionHasError: false });
                }}
                error={errors.institutionHasError}
              />
              {errors.institutionHasError && (
                <small className="text-danger">Institution is required</small>
              )}
            </div>

            <div className="custom-file mt-3">
              <input
                disabled={loading}
                type="file"
                className="custom-file-input"
                id="educationFile"
                accept="application/pdf"
                onClick={(e) => {
                  e.currentTarget.value = null;
                  setFile(null);
                }}
                onChange={(e) => {
                  setErrors({ ...errors, fileHasError: false });
                  setFile(e.target.files);
                }}
              />
              <label className="custom-file-label" htmlFor="educationFile">
                {file ? file[0].name : "Attach your certificate"}
              </label>
            </div>
            {errors.fileHasError && (
              <small className="text-danger">Attachment is required</small>
            )}
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={loading}
          onClick={handleSubmit}
          aria-label="Submit your certificate"
        >
          <span aria-hidden="true">
            {loading ? "Wait..." : !!selectedCertificate ? "Update" : "Save"}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, user, lookup }) => ({
  loading,
  user,
  lookup,
});

export default connect(mapStateToProps, {
  addOrUpdateUserCertificate,
})(CertificateForm);
