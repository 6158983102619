export const truncateString = (str, num = 22) => {
  if (str.length > num) return str.slice(0, num) + "...";
  else return str;
};

export const getSchoolYears = (currentDate) => {
  const d = currentDate ? new Date(currentDate) : new Date();
  const currentYear = d.getFullYear();
  let i = 0;
  let result = [];
  while (i <= 50) {
    result.push({ id: i + 1, name: (+currentYear - i).toString() });
    i++;
  }
  return result;
};

export const getYearByName = (data, name) => {
  return data.find((item) => item.name === name);
};

export const addZero = (value) => {
  if (value < 10) return `0${value}`;
  else return value;
};

export const openPopup = (url) => {
  let params = ", fullscreen=yes";
  // params += "width=" + screen.availWidth;
  // params += ", height=" + screen.availHeight;
  params += ", menubar=no";
  params += ", location=no";
  params += ", resizable=no";
  params += ", scrollbars=yes";
  params += ", status=yes";
  params += ", toolbar=no";
  // params += ", top=0, left=0";
  // params += ", directories=no";
  // params += ", titlebar=no";
  // params += ", dependent=yes";

  let newWin = window.open(url, "_blank", params);
  if (window.focus) {
    newWin.focus();
  }
  return false;
};
