import React, { useEffect } from "react";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const PdfViewer = (props) => {
  const { url,isBase64 } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <div style={{ height: "100%" }}>
      
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
            <Viewer
              fileUrl={url.includes(process.env.REACT_APP_FILES_URL) || !!isBase64?url: process.env.REACT_APP_FILES_URL +'/preview?url='+encodeURIComponent(url)}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker> 
       
      </div>
    </>
  );
};

export default PdfViewer;
