import moment from "moment";
import React from "react";

const CountDownDisplay = (props) => {
  return (
    <div>
      {props.type ? (
        <div>
          <div className="d-flex flex-row align-items-center">
            <DateTimeDisplay
              value={props.time.days}
              type={"Days"}
              isDanger={props.time.days === "00"}
              displayFor={props.type}
            />
            <div className="mx-1 font-weight-bold" aria-hidden="true">
              :
            </div>
            <DateTimeDisplay
              value={props.time.hours}
              type={"Hours"}
              isDanger={props.time.days === "00" && props.time.hours === "00"}
              displayFor={props.type}
            />
            <div className="mx-1 font-weight-bold" aria-hidden="true">
              :
            </div>
            <DateTimeDisplay
              value={props.time.minutes}
              type={"Mins"}
              isDanger={
                props.time.days === "00" &&
                props.time.hours === "00" &&
                props.time.minutes === "00"
              }
              displayFor={props.type}
            />
            <div className="mx-1 font-weight-bold" aria-hidden="true">
              :
            </div>
            <DateTimeDisplay
              value={props.time.seconds}
              type={"Seconds"}
              isDanger={
                props.time.days === "00" &&
                props.time.hours === "00" &&
                props.time.minutes === "00" &&
                props.time.seconds === "00"
              }
              displayFor={props.type}
            />
          </div>
          {props.examLocation && props.examDate && (
            <div className="text-center mt-1" style={{ fontSize: "12px" }}>
              <span className="text-primary font-weight-bold">
                {props.examLocation}
              </span>
              <span
                className="mr-1 text-secondary font-weight-bold"
                style={{ fontSize: "10px" }}
              >
                - {moment(props.examDate).format("ll")}
              </span>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="d-none d-md-block">
            {props.time.hours +
              "H:" +
              props.time.minutes +
              "Min:" +
              props.time.seconds +
              "Sec"}
          </div>
          <div className="d-md-none d-block" style={{ fontSize: "12px" }}>
            {props.time.hours +
              "H:" +
              props.time.minutes +
              "Min:" +
              props.time.seconds +
              "Sec"}
          </div>
        </>
      )}
    </div>
  );
};

export default CountDownDisplay;

export const DateTimeDisplay = ({ value, type, isDanger, displayFor }) => {
  return (
    <div
      className={`d-flex flex-column align-items-center px-1 border rounded-sm ${
        !isDanger ? "border-primary" : ""
      }`}
    >
      <p
        className={`font-weight-bold ${!isDanger ? "text-blue-main" : ""}`}
        style={
          displayFor === "small"
            ? {
                fontSize: "11px",
              }
            : {}
        }
      >
        {value}
      </p>
      <span
        className="mt-n3"
        style={
          displayFor === "small"
            ? {
                fontSize: "9px",
              }
            : { fontSize: "12px" }
        }
      >
        {type}
      </span>
    </div>
  );
};
