import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getAnnouncements } from "../../../store/applicant/actions";
import { connect, useDispatch } from "react-redux";
import CountDownTimer from "../../applicant/exam-portal/components/CountDownTimer";
import colors from "../../utils/colors";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import moment from "moment/moment";
const Announcements = (props) => {
  const { announcements, getAnnouncements, expanded, roundedValue, isForView } =
    props;

  const [isExpanded, setIsExpanded] = useState(expanded || true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!announcements.length) getAnnouncements();
  }, []);

  return (
    <section aria-labelledby="announcements-header">
      {!!announcements.length && (
        <>
          <div className="card elevated rounded border mt-4 bg-light">
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => setIsExpanded(!isExpanded)}
              className="card-header rounded-top bg-primary2 text-light text-uppercase"
              // role="button"
            >
              <span className="d-flex justify-content-between align-items-center">
                <Typography
                  variant="h6"
                  className="font-weight-light"
                  id="announcements-header"
                >
                  Announcements
                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  aria-expanded={isExpanded}
                  aria-label={
                    isExpanded
                      ? "show less announcement"
                      : "show more announcement"
                  }
                >
                  {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </span>
            </div>

            <div className={`card-body ${!isExpanded ? "d-none" : ""}`}>
              {announcements.map((announcement, index) => (
                <section
                  className="mb-2"
                  key={index}
                  aria-labelledby={`announcement-title-${announcement.id}`}
                >
                  <Card
                    className={`border ${
                      roundedValue || "rounded"
                    } bg-light w-full h-100`}
                    elevation={isForView ? 0 : 1}
                  >
                    <CardHeader
                      className="pb-0"
                      title={
                        <Typography
                          sx={{ fontSize: 16 }}
                          variant="subtitle1"
                          color={colors.blueLogo}
                          className="d-flex justify-content-between font-weight-bold text-primary2"
                          id={`announcement-title-${announcement.id}`}
                        >
                          {announcement.title}
                        </Typography>
                      }
                    />
                    <CardContent className="pt-0">
                      <div className="d-flex align-items-center flex-wrap">
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: 13 }}
                        >
                          <span className="text-capitalize">
                            {announcement.description}
                          </span>
                          {!!announcement.link && (
                            <p style={{ color: "#654D06" }}>
                              For more details click
                              <a
                                href={announcement.link}
                                target="_blank"
                                className="btn btn-link btn-sm"
                                rel="noreferrer noopener"
                                style={{
                                  color: "#0575aa",
                                }}
                              >
                                here
                              </a>
                            </p>
                          )}
                        </Typography>
                      </div>
                      {!!announcement.deadlineOn && (
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center flex-wrap">
                          <div className="d-flex flex-nowrap">
                            <div className="d-flex flex-column mr-3">
                              <div style={{ color: colors.blueLogo }}>
                                <CalendarMonthOutlinedIcon
                                  style={{
                                    fontSize: "12px",
                                    marginRight: "4px",
                                  }}
                                />
                                <Typography variant="caption">
                                  Posted on
                                </Typography>
                              </div>
                              <Typography
                                variant="body2"
                                className="font-weight-light"
                                style={{ fontSize: "13px" }}
                              >
                                {moment(announcement.postedOn).format("ll")}
                              </Typography>
                            </div>

                            <div className="d-flex flex-column mr-3">
                              <div style={{ color: colors.blueLogo }}>
                                <AccessTimeOutlinedIcon
                                  style={{
                                    fontSize: "12px",
                                    marginRight: "4px",
                                  }}
                                />
                                <Typography variant="caption">
                                  Deadline
                                </Typography>
                              </div>
                              <Typography
                                variant="body2"
                                className="font-weight-light"
                                style={{ fontSize: "13px" }}
                              >
                                {moment(announcement.deadlineOn).format("ll")}
                              </Typography>
                            </div>
                          </div>
                          <div className="mx-auto mx-md-0 mt-2 mb-n3 mt-1 mt-md-0">
                            <CountDownTimer
                              counterType="small"
                              defaultLeftDifferent={
                                announcement.closingTimeLeft
                                  ? announcement.closingTimeLeft
                                  : 0
                              }
                              onTimeOut={() =>
                                dispatch({
                                  type: "DELETE_ANNOUNCEMENT",
                                  id: announcement.id,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </section>
              ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};
const mapStateToProps = ({ announcements }) => {
  return { announcements };
};

export default connect(mapStateToProps, {
  getAnnouncements,
})(Announcements);
