import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { respondToJobOffer } from "../../../store/applicant/actions";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SearchBox from "../../comm/SearchBox";
import ReactPaginate from "react-paginate";
import axiosMiddleware from "../../utils/axios-middleware";
import { showError } from "../../toastify";
import NoResults from "../../comm/NoResults";
import { isEmpty } from "lodash";
import moment from "moment";

const OralExamDetailDialog = (props) => {
  const { user, showDialog, setShowDialog, application } = props;

  const [loading, setLoading] = useState(false);

  const [exam, setExam] = useState({});

  useEffect(() => {
    getOralExamDetail();
  }, []);

  const getOralExamDetail = async () => {
    try {
      setLoading(true);
      const { data } = await axiosMiddleware.get(
        `/api/recruitment/oral-exam-detail/${application.oralExamId}`
      );

      setExam(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">
              Oral exam details{" "}
              {!!application.isOralExamCanceled && (
                <>
                  / <span className="badge badge-danger">Canceled</span>
                </>
              )}{" "}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="pb-1">
          <div className="table-responsive rounded border ">
            {loading && isEmpty(exam) ? (
              <Skeleton variant="rectangular" width="100%" height={150} />
            ) : !isEmpty(exam) ? (
              <table className={`table table-sm table-striped mb-0`}>
                <tbody>
                  <tr>
                    <th className="text-right py-1">ExamVenue</th>
                    <td>
                      <strong>{exam.venue || "N/A"}</strong>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-right py-1">ExamDate</th>
                    <td>{moment(exam.seatingDate).format("ll")}</td>
                  </tr>
                  <tr>
                    <th className="text-right py-1">StartTime</th>
                    <td>{exam.startTime}</td>
                  </tr>
                  <tr>
                    <th className="text-right py-1">EndTime</th>
                    <td>{exam.endTime}</td>
                  </tr>
                  {!!exam.cancelationReason && (
                    <>
                      <tr>
                        <th className="text-right py-3 text-danger">
                          Cancellation reason
                        </th>
                        <td className="py-3">
                          <TextField
                            InputProps={{ readOnly: true }}
                            fullWidth
                            label=" Cancellation reason"
                            placeholder="cancelationReason"
                            value={exam.cancelationReason || " "}
                            size="small"
                            multiline
                            minRows={4}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <th className="text-right py-3">Notice</th>
                    <td className="py-3">
                      <TextField
                        InputProps={{ readOnly: true }}
                        fullWidth
                        label="Notice"
                        placeholder="Notice"
                        value={exam.notice || " "}
                        size="small"
                        multiline
                        minRows={4}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <NoResults />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { respondToJobOffer })(
  OralExamDetailDialog
);
