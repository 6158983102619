import CertificateForm from "../profile/components/CertificateForm";
import AdvertSkeleton from "../../comm/AdvertSkeleton";
import { getRequisitionCertificates } from "../../../store/applicant/actions";
import { useEffect } from "react";
import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";

const AdvertisementCertificates = (props) => {
  const {
    user,
    loading,
    advertisement,
    requisitionCertificates,
    getRequisitionCertificates,
    isHome,
    certificateQualified,
    setCertificateQualified,
    env,
  } = props;

  useEffect(() => {
    getRequisitionCertificates(advertisement.requisitionId, user?.id || 0);
  }, []);

  useEffect(() => {
    setCertificateQualified(
      !requisitionCertificates.length ||
        !!requisitionCertificates.find(({ qualified }) => qualified === true)
    );
  }, [requisitionCertificates]);

  return (
    <>
      {loading && !requisitionCertificates.length && <AdvertSkeleton />}
      {!!requisitionCertificates.length && (
        <div className="mt-3">
          <div className="d-flex align-items-center mb-2">
            <Typography
              variant="body2"
              component="h3"
              style={{ fontWeight: "bold" }}
            >
              Required certificates
            </Typography>
            {!certificateQualified &&
              !isHome &&
              !advertisement.advertisementId && (
                <span className="badge badge-warning ml-1">
                  You are not qualified!
                </span>
              )}
          </div>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {requisitionCertificates.map((certificate, index) => (
              <CertificateItem
                key={certificate.id}
                index={index}
                certificate={certificate}
                showDivider={requisitionCertificates.length > index + 1}
                isHome={isHome}
              />
            ))}
          </List>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ env, user, loading, requisitionCertificates }) => ({
  env,
  user,
  loading,
  requisitionCertificates,
});

export default connect(mapStateToProps, { getRequisitionCertificates })(
  AdvertisementCertificates
);

const CertificateItem = (props) => {
  const { index, certificate, showDivider, isHome } = props;

  return (
    <>
      <ListItem
        dense
        secondaryAction={
          !!certificate.qualified && (
            <IconButton edge="end" aria-label="certificate provided">
              <span className="material-icons-round text-success">
                verified
              </span>
            </IconButton>
          )
        }
      >
        <ListItemAvatar>
          <Avatar
            style={{
              fontSize: "16px",
              border: "1px solid #c8c6c6",
            }}
            className="bg-white text-dark "
          >
            {index + 1}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={
            <span className={`${"font-weight-light"}`}>
              {certificate.certificateName}
            </span>
          }
        />
      </ListItem>

      {showDivider && <Divider />}
    </>
  );
};
