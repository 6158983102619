import axios from "axios";
const pako = require("pako");

const compressJSON = (jsonArray) => {
  const jsonStr = JSON.stringify(jsonArray);
  const compressedData = pako.deflate(jsonStr, { to: "string" });
  return { _data_: compressedData };
};

const decompressJSON = (compressedData) => {
  try {
    if (compressedData._data_) {
      const compressedStr = compressedData._data_;
      const decompressedStr = pako.inflate(compressedStr, { to: "string" });
      return JSON.parse(decompressedStr);
    }
    return compressedData;
  } catch (error) {
    console.error("Decompression error:", error.message);
    return null;
  }
};

const axiosMiddleware = axios.create();

// Compress the data before sending the request
axiosMiddleware.interceptors.request.use((config) => {
  if (config.data && !config.ignoreEncryption) {
    config.data = compressJSON(config.data);
  }

  return config;
});

// Decompress the data before returning the response
axiosMiddleware.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = decompressJSON(response.data);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosMiddleware;
