import React, { useEffect, useState } from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";
import { connect } from "react-redux";
import { changePassword } from "../../../../store/comm/actions";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

export const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
export const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
export const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
export const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
export const eightCharsOrMore = /.{8,}/g; // eight characters or more

function ChangePassword(props) {
  const { loading, onShow, onClose, changePassword } = props;

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    return () => {
      setCurrentPassword("");
      setPassword("");
      setConfirmPassword("");
    };
  }, []);

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
    confirmed:
      password.length >= 8 &&
      confirmPassword.length >= password.length &&
      confirmPassword === password
        ? true
        : confirmPassword.length >= password.length &&
          confirmPassword !== password
        ? false
        : undefined,
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (
      confirmPassword !== password ||
      !currentPassword ||
      passwordStrength < 6
    )
      return;

    changePassword({ currentPassword, password }, onClose);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">Change password</span>
        </Typography>
        <IconButton
          aria-label="close change password form"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="row  justify-content-center mx-0 px-0 text-center mt-1">
          <div className="col-12 col-md-6 order-2 order-md-1 mb-2">
            <form onSubmit={handleChangePassword}>
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                className="border bg-white"
              >
                <InputLabel htmlFor="password">Current password</InputLabel>
                <OutlinedInput
                  autoFocus
                  id="password"
                  placeholder="Current password"
                  name="currentPassword"
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disableFocusRipple
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <span className="material-icons">visibility_off</span>
                        ) : (
                          <span className="material-icons">visibility</span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Current password"
                />
              </FormControl>

              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                className="border mt-3 bg-white"
              >
                <InputLabel htmlFor="password">New password</InputLabel>
                <OutlinedInput
                  id="password"
                  placeholder="New password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disableFocusRipple
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <span className="material-icons">visibility_off</span>
                        ) : (
                          <span className="material-icons">visibility</span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New password"
                />
              </FormControl>

              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                className="mt-3 bg-white"
              >
                <InputLabel htmlFor="password">Confirm Password</InputLabel>
                <OutlinedInput
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disableFocusRipple
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <span className="material-icons">visibility_off</span>
                        ) : (
                          <span className="material-icons">visibility</span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
              </FormControl>

              {!loading && (
                <Button
                  type="submit"
                  disabled={
                    loading ||
                    !currentPassword ||
                    confirmPassword !== password ||
                    passwordStrength < 6
                  }
                  variant="contained"
                  disableElevation
                  className="btn btn-block text-light mt-3"
                >
                  Change password
                </Button>
              )}

              {loading && (
                <Button
                  disabled
                  type="button"
                  variant="contained"
                  disableElevation
                  className="btn btn-block text-light mt-3"
                >
                  Changing password...
                </Button>
              )}
            </form>
          </div>

          <div className="col-12 col-md-6 order-1 order-md-2 mb-2">
            <Alert icon={false} severity="info" className="mb-2 rounded">
              <div className=" rounded text-left px-3 mb-3">
                <div className="w-full rounded password-strength-status-dialog"></div>
                <span>Password must include:</span>
                <div
                  className={`d-flex flex-row align-items-center ${
                    passwordTracker.lowercase ? "text-success" : ""
                  }`}
                >
                  {passwordTracker.lowercase ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  )}
                  <span className="ml-1">lowercase(s)</span>
                </div>
                <div
                  className={`d-flex flex-row align-items-center ${
                    passwordTracker.uppercase ? "text-success" : ""
                  }`}
                >
                  {passwordTracker.uppercase ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  )}
                  <span className="ml-1">UPPERCASE(S)</span>
                </div>
                <div
                  className={`d-flex flex-row align-items-center ${
                    passwordTracker.number ? "text-success" : ""
                  }`}
                >
                  {passwordTracker.number ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  )}
                  <span className="ml-1">number(s)</span>
                </div>
                <div
                  className={`d-flex flex-row align-items-center ${
                    passwordTracker.specialChar ? "text-success" : ""
                  }`}
                >
                  {passwordTracker.specialChar ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  )}
                  <span className="ml-1">special character(s)</span>
                </div>
                <div
                  className={`d-flex flex-row align-items-center ${
                    passwordTracker.eightCharsOrGreater ? "text-success" : ""
                  }`}
                >
                  {passwordTracker.eightCharsOrGreater ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  )}
                  <span className="ml-1">8 or more characters </span>
                </div>
                <div
                  className={`d-flex flex-row align-items-center ${
                    passwordTracker.confirmed
                      ? "text-success"
                      : passwordTracker.confirmed === false
                      ? "text-danger"
                      : ""
                  }`}
                >
                  {passwordTracker.confirmed ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : passwordTracker.confirmed === false ? (
                    <UnpublishedRoundedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      style={{ fontSize: "16px", marginTop: "3px" }}
                    />
                  )}
                  <span className="ml-1">confirmed</span>
                </div>
              </div>
            </Alert>
          </div>
        </div>

        <style>
          {`
          .password-strength-status-dialog {
            height: 0.4rem;
            background-color: lightgrey;
            border-radius: 3px;
            margin: 0.5rem 0;
          }

          .password-strength-status-dialog::before {
            content: "";
            background-color: ${
              ["red", "orange", "#03a2cc", "#03a2cc", "#33FFBD", "#33FF57"][
                passwordStrength - 1
              ] || ""
            };
            height: 100%;
            width: ${(passwordStrength / 6) * 100}%;
            display: block;
            border-radius: 3px;
            transition: width 0.2s;
          }
        `}
        </style>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4"></DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ loading }) => ({ loading });

export default connect(mapStateToProps, { changePassword })(ChangePassword);
