import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { startExam } from "../../../store/exam/actions";
import { getSingleApplication } from "../../../store/applicant/actions";
import Header from "./components/Header";
import DoExam from "./components/DoExam";
import ExamIntroduction from "./components/ExamIntroduction";
import loadingGif from "../../assets/loading.gif";
import DisplayMarks from "./components/DisplayMarks";
import axios from "axios";
import { showError } from "../../toastify";
import axiosMiddleware from "../../utils/axios-middleware";
import Bowser from "bowser";
import { browsersData, isBrowserSupported } from "../../utils";

function Index(props) {
  const { startExam } = props;
  const [clientTime, setClientTime] = useState(new Date());
  const [serverTime, setServerTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);
  const [isLoadingTime, setIsLoadingTime] = useState(false);

  const location = useLocation();
  const { examSession } = props;
  const { applicationId } = location.state;
  const [application, setApplication] = useState(null);
  const browser = Bowser.getParser(window.navigator.userAgent);

  const dispatch = useDispatch();

  useEffect(() => {
    getServerTime();
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_APPLICATIONS", data: [] });
    getSingleApplication();
  }, []);

  const getSingleApplication = async () => {
    try {
      const { data } = await axiosMiddleware.get(
        "/api/recruitment/single-application/" + applicationId
      );

      setApplication(data);
    } catch (error) {}
  };

  const getServerTime = async () => {
    try {
      setIsLoadingTime(true);
      const { data } = await axiosMiddleware.get("/api/time");

      const serverTime = new Date(data.serverTime);
      setServerTime(serverTime);
      // Calculate the difference in milliseconds
      const diff = serverTime - new Date();
      setTimeDifference(diff);
      setIsLoadingTime(false);
    } catch (error) {
      console.error("Error fetching server time:", error);
    }
  };

  const [userIp, setUserIp] = useState("");
  const [displayMarks, setDisplayMarks] = useState(false);

  const exitFullScreen = () => {
    if (document.fullscreenElement) document.exitFullscreen();
  };

  useEffect(() => {
    getClientIp();
  }, []);

  const getClientIp = async () => {
    try {
      const { data } = await axios.get(`https://api.ipify.org`);

      setUserIp(data);
    } catch (error) {}
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const onStartExam = (examKey) => {
    if (document.fullscreenEnabled) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement
          .requestFullscreen()
          .then(() => {
            setIsFullScreen(true);

            startExam(
              {
                applicationId: application.id,
                ipAddress: userIp,
                examKey,
              },
              exitFullScreen
            );
          })
          .catch((error) => {
            showError("Browser fullscreen error");
          });
      }
    } else {
      showError("Your browser is not supported. Please, change the browser");
    }
  };

  return (
    <div>
      <Header />

      {!isBrowserSupported(browser) ? (
        <>
          <div className="text-center mt-4">
            <h1>Browser Not Supported</h1>
            <p>
              Please update your browser to the latest version to start exam.
            </p>
            <BrowserSupportTable browser={browser} />
          </div>
        </>
      ) : (
        <>
          
              {!!application ? (
                <>
                  {(!examSession || !isFullScreen) && !displayMarks ? (
                    <ExamIntroduction
                      application={application}
                      onStartExam={onStartExam}
                      getSingleApplication={getSingleApplication}
                    />
                  ) : !displayMarks ? (
                    <DoExam
                      application={application}
                      ipAddress={userIp}
                      setDisplayMarks={setDisplayMarks}
                      isFullScreen={isFullScreen}
                      setIsFullScreen={setIsFullScreen}
                      exitFullScreen={exitFullScreen}
                    />
                  ) : (
                    <DisplayMarks />
                  )}
                </>
              ) : (
                <div className="loader">
                  <img src={loadingGif} height="128" alt="loading" />
                </div>
              )}
            
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ user, loading, examSession }) => ({
  user,
  loading,
  examSession,
});

export default connect(mapStateToProps, {
  getSingleApplication,
  startExam,
})(Index);
const BrowserSupportTable = ({ browser }) => {
  const browserName = browser.getBrowserName();

  const supported = browsersData.find(({ name, slugName }) =>
    [name.toLowerCase(), slugName.toLowerCase()].includes(
      browserName.toLowerCase()
    )
  );
  return (
    <>
      <div className="mt-5">
        <h4>Supported Browsers and Minimum Versions</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Browser</th>
              <th>Minimum Version</th>
              <th>Update Instructions</th>
            </tr>
          </thead>
          <tbody>
            {browsersData.length &&
              browsersData.map((brw, index) => (
                <tr key={index}>
                  <td>{brw.name}</td>
                  <td>
                    {brw.sign}
                    {brw.minVersion}
                  </td>
                  <td>
                    <a
                      href={brw.downloadLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Latest Version
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {supported && (
          <a
            className="btn btn-primary"
            href={supported.downloadLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Update now
          </a>
        )}
      </div>
    </>
  );
};
