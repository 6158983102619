import React, { useEffect, useState } from "react";
import { addOrUpdateDisability } from "../../../../store/profile/actions";
import { connect } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

const DisabilityForm = (props) => {
  const {
    loading,
    lookup,
    addOrUpdateDisability,
    selectedDisability,
    onShow,
    onClose,
  } = props;

  const [disability, setDisability] = useState(null);
  const [disabilityLevel, setDisabilityLevel] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setDisability(selectedDisability?.disability || null);
    setDisabilityLevel(selectedDisability?.disabilityLevel || null);
  }, []);

  const [errors, setErrors] = useState({
    disabilityHasError: false,
    disabilityLevelHasError: false,
    fileHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      disabilityHasError: false,
      disabilityLevelHasError: false,
      fileHasError: false,
      hasError: false,
    };

    if (!disability) {
      error.disabilityHasError = true;
      error.hasError = true;
    }

    if (!disabilityLevel && disability?.id !== 1) {
      error.disabilityLevelHasError = true;
      error.hasError = true;
    }

    if (!file && disability?.id !== 1) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!formValidator()) {
      const formData = new FormData();
      formData.append("id", selectedDisability?.id || "");
      formData.append("disabilityId", disability.id);
      formData.append("disabilityLevelId", disabilityLevel?.id || "");
      file && formData.append("file", file[0]);

      addOrUpdateDisability(formData, onClose);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedDisability ? "Add " : "Update "}
            disability
          </span>
        </Typography>
        <IconButton
          aria-label="close disability form"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-md-4 mt-2">
          <form>
            <div className="form-group mt-3">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={disability}
                options={lookup.disabilities || []}
                disabled={loading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, disability) => {
                  setDisability(disability || null);
                  setErrors({ ...errors, disabilityHasError: false });
                }}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, disability) => (
                  <Box component="li" {...props} key={disability.id}>
                    {disability.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "25px !important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px !important",
                    }}
                    {...params}
                    label="Select disability"
                    placeholder="Select disability"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                    error={errors.disabilityHasError}
                  />
                )}
              />
              {errors.disabilityHasError && (
                <small className="text-danger">Select disability</small>
              )}
            </div>

            {disability?.id !== 1 && (
              <div className="form-group mt-3">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={disabilityLevel}
                  options={lookup.disabilityLevels}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, disabilityLevel) => {
                    setDisabilityLevel(disabilityLevel || null);
                    setErrors({ ...errors, disabilityLevelHasError: false });
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, disabilityLevel) => (
                    <Box component="li" {...props} key={disabilityLevel.id}>
                      {disabilityLevel.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Select disability level"
                      placeholder="Select disability level"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                      error={errors.disabilityLevelHasError}
                    />
                  )}
                />
                {errors.disabilityLevelHasError && (
                  <small className="text-danger">Select disability level</small>
                )}
              </div>
            )}

            {disability?.id !== 1 && (
              <div className="custom-file mt-3">
                <input
                  disabled={loading}
                  type="file"
                  className="custom-file-input"
                  id="educationFile"
                  accept="application/pdf"
                  onClick={(e) => {
                    e.currentTarget.value = null;
                    setFile(null);
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, fileHasError: false });
                    setFile(e.target.files);
                  }}
                />
                <label className="custom-file-label" htmlFor="educationFile">
                  {file ? file[0].name : "Proof of disability"}
                </label>
              </div>
            )}
            {errors.fileHasError && (
              <small className="text-danger">
                Please, attach proof of disability
              </small>
            )}
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={loading}
          onClick={handleSubmit}
          aria-label="Submit your disability"
        >
          <span aria-hidden="true">
            {loading ? "Wait..." : !!selectedDisability ? "Update" : "Save"}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading, lookup }) => ({
  user,
  loading,
  lookup,
});

export default connect(mapStateToProps, { addOrUpdateDisability })(
  DisabilityForm
);
