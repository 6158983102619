import axios from "../../app/utils/axios-middleware";
import { showSuccess, showError } from "../../app/toastify";

import TYPES from "./action-types";
import COM_TYPES from "../comm/action-types";
const types = { ...COM_TYPES, ...TYPES };

export const startExam = (formData, exitFullScreen) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.SET_QUETIONS, data: [] });
      dispatch({ type: types.SET_EXAM_SESSION, data: null});
      dispatch({ type: types.SET_ANSWERS, data: [] });
      const { data } = await axios.post(`/api/examination/start`, formData);

      dispatch({ type: types.SET_QUETIONS, data: data.questions });
      dispatch({ type: types.SET_EXAM_SESSION, data: data.examSession });
      dispatch({ type: types.SET_ANSWERS, data: data.answers });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      exitFullScreen();
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveAnswer = (formData, showToast = true) => {
  return async (dispatch) => {
    try {
      if (showToast) dispatch({ type: types.START_LOADING });

      await axios.post(`/api/examination/answers`, formData);

      dispatch({
        type: types.ADD_OR_UPDATE_ANSWER,
        data: {
          examQuestionId: formData.examQuestionId,
          applicantExamSessionId: formData.applicantExamSessionId,
          answer: formData.answer,
        },
      });

      if (showToast) showSuccess("Saved successfully");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (showToast) showError(error);
    }
  };
};

export const submitExam = (
  applicantExamSessionId,
  setIsSubmiting,
  setDisplayMarks
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setIsSubmiting(true);

      const { data } = await axios.post(`/api/examination/submit`, {
        applicantExamSessionId,
      });

      dispatch({
        type: types.SET_RESULTS,
        data,
      });

      setIsSubmiting(false);

      setDisplayMarks(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
      setIsSubmiting(false);
    }
  };
};

export const attemptToCheat = (examSessionId, eventType, setIsFullScreen) => {
  return async (dispatch) => {
    try {
      await axios.post(`/api/examination/attempt-to-cheat`, {
        examSessionId,
        eventType,
      });

      setIsFullScreen(false);
    } catch (error) {
      setIsFullScreen(false);
    }
  };
};

export const refleshExamSession = (
  applicantExamSessionId,
  setIsRefleshing,
  setCounters
) => {
  return async (dispatch) => {
    try {
      setIsRefleshing(true);
      const { data } = await axios.post(`/api/examination/reflesh-session`, {
        applicantExamSessionId,
      });
      setIsRefleshing(false);

      if (setCounters) setCounters();

      dispatch({ type: types.SET_EXAM_SESSION, data });
    } catch (error) {}
  };
};
