import React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { connect } from "react-redux";
import colors from "../../../utils/colors";
import logoLG from "../../../assets/logo-lg.png";
import logoSM from "../../../assets/logo-sm.png";

const Header = (props) => {
  const { user } = props;

  return (
    <header role="banner">
      <Box sx={{ flexGrow: 1 }} id="checking" className="mb-3">
        <AppBar position="static" elevation={1} className="app-bar">
          <Toolbar
            className="px-3 d-flex justify-content-between"
            variant="dense"
          >
            <div className="d-flex align-items-center">
              <img
                style={{
                  width: "145px",
                }}
                src={logoLG}
                className="mr-2 d-none d-md-inline"
                alt="Logo"
              />
              <img
                style={{
                  width: "50px",
                }}
                src={logoSM}
                className="mr-2 d-inline d-md-none"
                alt="Logo"
                aria-hidden="true"
              />
            </div>
            <div>
              <Typography
                component="header"
                style={{ color: colors.blueLogo, fontSize: "20px" }}
                className="font-weight-bold d-none d-md-block"
              >
                Examination Portal
              </Typography>
              <Typography
                component="header"
                style={{ color: colors.blueLogo, fontSize: "14px" }}
                className="font-weight-bold d-md-none d-block"
                arial-hidden="true"
              >
                Examination Portal
              </Typography>
            </div>

            <div className="d-flex align-items-center">
              <div className="text-primary d-none d-md-flex">
                <strong
                  className="d-flex align-items-center"
                  aria-label={`${user.lastName} ${user.firstName} is about to start exam.`}
                >
                  <span
                    style={{ borderRadius: "0px" }}
                    className="role text-truncate badge text-uppercase"
                    aria-hidden="true"
                  >
                    {user.lastName} {user.firstName}
                  </span>
                </strong>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, {})(Header);
