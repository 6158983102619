import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUserDisabilities,
  deleteUserDisability,
  getDisabilities,
  getDisabilityLevels,
  updateUserFile,
} from "../../../../store/profile/actions";
import colors from "../../../utils/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import NoResults from "../../../comm/NoResults";
import DisabilityForm from "./DisabilityForm";
import AdvertSkeleton from "../../../comm/AdvertSkeleton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import AlertDialog from "../../../comm/AlertDialog";
import PreviewPdfUrl from "../../../comm/PreviewPdfUrl";
import LockIcon from "@mui/icons-material/Lock";

export const Disabilities = (props) => {
  const {
    env,
    loading,
    onWait,
    userDisabilities,
    getUserDisabilities,
    deleteUserDisability,
    getDisabilities,
    getDisabilityLevels,
    lookup,
    updateUserFile,
  } = props;

  useEffect(() => {
    getUserDisabilities();

    if (!lookup?.disabilityLevels?.length) getDisabilityLevels();
    if (!lookup?.disabilities?.length) getDisabilities();
  }, []);

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showDisabilityForm, setShowDisabilityForm] = useState(false);
  const [selectedDisability, setSelectedDisability] = useState(null);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="disabilities">
        <CardContent>
          {loading && !onWait && !userDisabilities.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <div>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: colors.blueLogo }}
                  >
                    Disability
                  </Typography>
                </div>

                <Button
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  disabled={loading}
                  onClick={() => setShowDisabilityForm(true)}
                  size="small"
                  aria-label="Add new disability"
                >
                  <span aria-hidden="true">
                    <span className="d-none d-md-inline mr-1">Add</span> new
                  </span>
                </Button>
              </div>

              {userDisabilities.length === 0 ? (
                <NoResults />
              ) : (
                userDisabilities.map((disability, index) => (
                  <DisabilityItemCard
                    key={disability.id}
                    index={index}
                    disability={disability}
                    loading={loading}
                    deleteUserDisability={deleteUserDisability}
                    setSelectedDisability={setSelectedDisability}
                    setShowDisabilityForm={setShowDisabilityForm}
                    setLoadingDocument={setLoadingDocument}
                    updateUserFile={updateUserFile}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showDisabilityForm && (
        <DisabilityForm
          selectedDisability={selectedDisability}
          onShow={showDisabilityForm}
          onClose={() => {
            setShowDisabilityForm(false);
            setSelectedDisability(null);
          }}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  env,
  loading,
  onWait,
  userDisabilities,
  lookup,
}) => ({
  user,
  env,
  loading,
  onWait,
  userDisabilities,
  lookup,
});

export default connect(mapStateToProps, {
  getUserDisabilities,
  deleteUserDisability,
  getDisabilities,
  getDisabilityLevels,
  updateUserFile,
})(Disabilities);

const DisabilityItemCard = (props) => {
  const {
    index,
    env,
    loading,
    disability,
    deleteUserDisability,
    setSelectedDisability,
    setShowDisabilityForm,
    setLoadingDocument,
    updateUserFile,
  } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          title={<Typography>{disability?.disability?.name}</Typography>}
          subheader={
            disability.disabilityId !== 1 && (
              <>
                Level of disability:{" "}
                <span className="btn-link">
                  {disability?.disabilityLevel?.name}{" "}
                </span>
              </>
            )
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                {disability.disabilityId !== 1 && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (disability.docReferenceId) {
                        setOnViewDocument(true);
                      } else {
                        updateUserFile(
                          disability,
                          "CHANGE_DISABILITY_FILE",
                          disability.userId + "-" + disability.disability.name,
                          setOnViewDocument,
                          setLoadingDocument
                        );
                      }
                    }}
                  >
                    <FilePresentIcon
                      // fontSize="large"
                      style={{
                        color: !!disability.docReferenceId
                          ? "#fe4066"
                          : "#c57c7cb8",
                      }}
                    />
                  </IconButton>
                )}
                {disability.locked && (
                  <IconButton size="small" aria-label="settings">
                    <LockIcon />
                  </IconButton>
                )}
                {(!disability.locked || env !== "prod") && (
                  <>
                    <IconButton
                      size="small"
                      // className="ml-n2"
                      onClick={handleOpenMenu}
                      aria-label="settings"
                    >
                      <Badge color="info">
                        <span className="material-icons">more_vert</span>
                      </Badge>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 2,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 15,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setSelectedDisability(disability);
                            setShowDisabilityForm(true);
                          }}
                          className="text-dark font-weight-light"
                        >
                          <span className="material-icons mr-1">edit_note</span>
                          Edit
                        </MenuItem>
                      </span>

                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setOnDelete(true);
                          }}
                          className="text-danger font-weight-light"
                        >
                          <span className="material-icons mr-1">remove</span>{" "}
                          Remove
                        </MenuItem>
                      </span>
                    </Menu>
                  </>
                )}
              </div>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserDisability(disability.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this disability?"
          disabled={loading}
        />
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `disability document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              disability.docReferenceId,
          }}
        />
      )}
    </>
  );
};
