import React from "react";
// import Breadcrumb from "./Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import FolderSpecialRoundedIcon from "@mui/icons-material/FolderSpecialRounded";

const MenuBar = () => {
  const location = useLocation();

  return (
    <nav role="navigation">
      <div className="d-flex flex-row align-items-center justify-content-between modules-menu">
        <div className="d-flex align-items-center flex-nowrap">
          <Link
            className={`py-0 btn btn-link text-truncate btn-sm ${
              location.pathname.includes(`/applicant/advertisements`)
                ? "active"
                : ""
            }`}
            to="/applicant/advertisements"
          >
            <span className="d-inline-flex align-items-center">
              <WorkRoundedIcon className="mr-1" />
              <span>Advertisements</span>
            </span>
          </Link>

          <Link
            className={`py-0 btn text-truncate btn-link btn-sm ${
              location.pathname.includes(`/applicant/applications`)
                ? "active"
                : ""
            }`}
            to="/applicant/applications"
          >
            <span className="d-inline-flex align-items-center">
              <FolderSpecialRoundedIcon className="mr-1" />
              <span>My Applications</span>
            </span>
          </Link>
        </div>
      </div>
      {/* <nav>
        <div
          className="py-1 d-flex flex-wrap justify-content-between mt-0 align-items-center px-3"
          style={{
            backgroundColor: "#078ece",
            borderBottom: "1px solid #078ece",
            borderTop: "1px solid #078ece",
          }}
        >
          <Breadcrumb />
        </div>
      </nav> */}
    </nav>
  );
};

// const isJobActive = (location) => {
//   if (
//     location.pathname.includes("/applicant") &&
//     !location.pathname.includes("/profile") &&
//     !location.pathname.includes("/applications") &&
//     !location.pathname.includes("/appeals") &&
//     !location.pathname.includes("/waiting-list")
//   )
//     return true;
//   return false;
// };

export default MenuBar;
