import React, { useState } from "react";

function Calculator() {
  const [resultValue, setResultValue] = useState("0");

  const handleBtnClick = (buttonValue) => {
    if (resultValue === "0") {
      setResultValue(buttonValue);
    } else {
      setResultValue(resultValue + buttonValue);
    }
  };

  const handleCalculate = () => {
    try {
      setResultValue(eval(resultValue).toString());
    } catch (error) {
      setResultValue("Error");
    }
  };

  const handleClear = () => {
    setResultValue("0");
  };

  return (
    <div className="calculator" aria-label="Exam calculator">
      <label htmlFor="cal-result" className="sr-only">
        Calculator Result
      </label>
      <input
        id="cal-result"
        style={{ fontSize: "14px" }}
        type="text"
        className="form-control mb-3"
        value={resultValue}
        readOnly
        aria-label={`Result is ${resultValue}`}
      />

      <div className="input-group">
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("7")}
        >
          7
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("8")}
        >
          8
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("9")}
        >
          9
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("+")}
          aria-label="Addition"
        >
          <span aria-hidden="true">+</span>
        </button>
      </div>
      <div className="input-group">
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("4")}
        >
          4
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("5")}
        >
          5
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("6")}
        >
          6
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("-")}
          aria-label="Subtraction"
        >
          <span aria-hidden="true">-</span>
        </button>
      </div>
      <div className="input-group">
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("1")}
        >
          1
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("2")}
        >
          2
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("3")}
        >
          3
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("*")}
          aria-label="Multiplication"
        >
          <span aria-hidden="true">*</span>
        </button>
      </div>
      <div className="input-group ">
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("0")}
        >
          0
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={handleClear}
          aria-label="Clear"
        >
          <span aria-hidden="true">C</span>
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={handleCalculate}
          aria-label="Calculate"
        >
          <span aria-hidden="true">=</span>
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => handleBtnClick("/")}
          aria-label="Division"
        >
          <span aria-hidden="true">/</span>
        </button>
      </div>
    </div>
  );
}

export default Calculator;
