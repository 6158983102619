import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import FolderSpecialRoundedIcon from "@mui/icons-material/FolderSpecialRounded";
import { logout } from "../../store/comm/actions";
import defaultProfile from "../assets/default-profile.jpg";

export const Drawer = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [openProfile, setOpenProfile] = useState(false);

  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed ${
          props.drawerState ? "active" : ""
        } overflow-auto`}
        aria-hidden={!props.drawerState}
        tabIndex={props.drawerState ? 5 : -1}
      >
        <div style={{ overflowY: "auto" }}>
          <div
            className="drawer-user"
            style={{ borderBottom: "1px solid #d8d9da" }}
          >
            {props.user.profileImage && (
              <img
                style={{ width: "100px", height: "120px" }}
                src={`data:image/png;base64,${props.user.profileImage}`}
                className="img-fluid rounded mb-2"
                alt={props.user.firstName + "'s profile image"}
                aria-hidden="true"
              />
            )}
            {!props.user.profileImage && (
              <img
                style={{ width: "100px", height: "100px" }}
                src={defaultProfile}
                className="img-fluid rounded mb-2"
                alt={props.user.firstName + "'s profile image"}
                aria-hidden="true"
              />
            )}

            <div>
              <Tooltip title="Go to your profile">
                <Link
                  to="/applicant/profile"
                  style={{ color: "#b8ff07" }}
                  className="font-weight-bold text-uppercase"
                  onClick={() => {
                    props.setDrawerState(false);
                    setOpenProfile(false);
                  }}
                >
                  {props.user.firstName} {props.user.lastName}
                </Link>
              </Tooltip>
            </div>
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
          </div>
          <List>
            {/* handle jobs */}
            <ListItem
              disablePadding
              className={`nav-item ${isJobActive(location) ? "active" : ""}`}
              onClick={() => props.setDrawerState(false)}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <WorkRoundedIcon />
                </ListItemIcon>
                <Link to="/applicant" className="text-dark">
                  <ListItemText
                    primary="Advertisement"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            {/* handle applications */}
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/applicant/applications")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                props.setDrawerState(false);
                setOpenProfile(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <FolderSpecialRoundedIcon />
                </ListItemIcon>
                <Link to="/applicant/applications" className="text-dark">
                  <ListItemText
                    primary="My Applications"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            {/* <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/applicant/waiting-list")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                props.setDrawerState(false);
                setOpenProfile(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <FactCheckRoundedIcon />
                </ListItemIcon>
                <Link to="/applicant/waiting-list" className="text-dark">
                  <ListItemText
                    primary="Waiting List"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem> */}

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/applicant/profile") ? "active" : ""
              }`}
              onClick={() => {
                props.setDrawerState(false);
                setOpenProfile(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <AccountCircleRoundedIcon />
                </ListItemIcon>
                <Link to="/applicant/profile" className="text-dark">
                  <ListItemText
                    primary="Profile"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <Button
          className="btn btn-block mb-3"
          color="error"
          style={
            openProfile ? undefined : { position: "absolute", bottom: "0" }
          }
          onClick={() => props.logout(history)}
        >
          <i
            className="fas fa-sign-out-alt mr-2 "
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </Button>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${
          props.drawerState ? "d-block" : "d-none"
        } `}
        onClick={() => {
          props.setDrawerState(false);
          setOpenProfile(false);
        }}
      ></div>
    </React.Fragment>
  );
};

const isJobActive = (location) => {
  if (
    location.pathname.includes("/applicant/applications") ||
    location.pathname.includes("/applicant/waiting-list") ||
    location.pathname.includes("/applicant/profile")
  )
    return false;
  return true;
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { logout })(Drawer);
