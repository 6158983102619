import { ToastContainer } from "react-toastify";

const ToastifyMessage = () => {
  return (
    <ToastContainer
      position="top-right"
      // autoClose={false}
      autoClose={15000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
      pauseOnHover
      // limit={2}
      role="alert"
    />
  );
};

export default ToastifyMessage;
