const types = {
  // SETTINGS TYPES
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  START_WAIT: "START_WAIT",
  END_WAIT: "END_WAIT",
  SET_ENV: "SET_ENV",

  // AUTH TYPES
  SET_USER: "SET_USER",

  LOGOUT: "LOGOUT",
  CLEAN_STATE: "CLEAN_STATE",
};

export default types;
