import React from "react";
import { connect } from "react-redux";

const QuestionList = (props) => {
  const { questions, answers, currentQuestionNo, goToNextQuestion } = props;

  const isQuestionAnswered = (examQuestionId) => {
    if (
      answers.find(
        (item) => !!item.answer && +item.examQuestionId === +examQuestionId
      )
    )
      return true;
    return false;
  };

  return (
    <div
      className="mt-2 mb-3 rounded elevated-1 px-3 py-2"
      aria-label="List of all questions"
    >
      {/* title */}
      <div
        className="d-flex flex-row align-item-center justify-content-between"
        aria-label={`Exam is composed with ${questions.length}, and you ${
          !!answers.length
            ? `have answered ${answers.length} questions.`
            : `haven't answered any question yet.`
        }`}
      >
        <h5 className="font-weight-bold mb-3" aria-hidden="true">
          All questions
        </h5>

        <span aria-hidden="true">
          {answers.length}/{questions.length}
        </span>
      </div>
      <div
        className="row align-items-center justify-content-center no-gutters"
        style={{ overflowY: "auto" }}
      >
        {questions.map(
          (question, index) =>
            (!!question.id || question.id === 0) && (
              <div
                className="col-3 px-1"
                key={index + question?.id + Math.random()}
              >
                <button
                  onClick={() => goToNextQuestion(index + 1)}
                  style={{ width: "100%" }}
                  className={`btn text-white badge badge-${
                    questions[currentQuestionNo - 1]?.questionNo ===
                    question?.questionNo
                      ? "primary"
                      : isQuestionAnswered(question?.id)
                      ? "success"
                      : "secondary disabled"
                  }`}
                  aria-label={`Question ${index + 1}, ${
                    isQuestionAnswered(question?.id)
                      ? "Answered"
                      : "Not Answered"
                  }`}
                >
                  <span aria-hidden="true">Q.{index + 1}</span>
                </button>
              </div>
            )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  questions,
  // examSession,
  answers,
  currentQuestionNo,
}) => ({
  user,
  questions,
  // examSession,
  answers,
  loading,
  currentQuestionNo,
});

export default connect(mapStateToProps, {})(QuestionList);
