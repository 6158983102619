export const browsersData = [
  {
    name: "Google Chrome",
    slugName: "Chrome",
    minVersion: 61,
    downloadLink:
      "https://www.google.com/chrome/next-steps.html?statcb=1&installdataindex=empty&defaultbrowser=0",
    sign: ">=",
  },
  {
    name: "Mozilla Firefox",
    slugName: "Firefox",
    minVersion: 60,
    downloadLink:
      "https://support.mozilla.org/en-US/kb/update-firefox-latest-release",
    sign: ">=",
  },
  {
    name: "Safari",
    slugName: "Safari",
    minVersion: 10,
    downloadLink: "https://support.apple.com/en-us/102665",
    sign: ">=",
  },
  {
    name: "Microsoft Edge",
    slugName: "Edge",
    minVersion: 79,
    downloadLink: "https://www.microsoft.com/en-us/edge/download?form=MA13FJ",
    sign: ">=",
  },
  {
    name: "Opera",
    slugName: "Opera",
    minVersion: 48,
    downloadLink: "https://www.opera.com/browsers",
    sign: ">=",
  },
];

export const isBrowserSupported = (browser) => {
  const browserName = browser.getBrowserName();
  const browserVersion = browser.getBrowserVersion();
  const supported = browsersData.find(
    ({ name, slugName, minVersion }) =>
      [name.toLowerCase(), slugName.toLowerCase()].includes(
        browserName.toLowerCase()
      ) && parseInt(browserVersion, 10) >= parseInt(minVersion)
  );
  if (supported) return true;

  // Add checks for other browsers if necessary
  return false;
};


export const isEmailValid = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailPattern.test(email);
};