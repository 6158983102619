import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import SearchBox from "../../comm/SearchBox";
import ReactPaginate from "react-paginate";
import axiosMiddleware from "../../utils/axios-middleware";
import { showError } from "../../toastify";
import NoResults from "../../comm/NoResults";
import AppealForm from "./AppealForm";
import CountDownTimer from "../exam-portal/components/CountDownTimer";
import AppealPreviewDialog from "./AppealPreviewDialog";

const ShowApplicantCandidates = (props) => {
  const { showDialog, setShowDialog, application } = props;

  const [loading, setLoading] = useState(false);
  const [searchCandidate, setSearchCandidate] = useState("");

  const [candidateList, setCandidateList] = useState([]);

  // ### PAGINATION
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [filteredData, setFilteredData] = useState([]);
  const [paginatedCandidates, setPaginatedCandidates] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getCandidateList();
  }, []);

  useEffect(() => {
    if (filteredData) {
      setPaginatedCandidates(filteredData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [filteredData, itemOffset, endOffset]);

  useEffect(() => {
    if (candidateList) {
      if (searchCandidate !== "") {
        const value = searchCandidate?.toLowerCase();
        setFilteredData(
          candidateList.filter(
            (item) =>
              (item.names || "").toLowerCase().includes(value) ||
              item.statusName.toLowerCase().startsWith(value) ||
              (item.isMe && value === "me")
          )
        );
      } else {
        setFilteredData(candidateList);
      }
    }
  }, [candidateList, searchCandidate]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      (filteredData ? filteredData.length : 1);
    setItemOffset(newOffset);
  };

  const getCandidateList = async () => {
    try {
      setLoading(true);
      const { data } = await axiosMiddleware.get(
        `/api/recruitment/all-candidates-shortlisted/${application.advertisementId}`
      );

      setCandidateList(data);

      setLoading(false);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch);
  };

  const [showAppealForm, setShowAppealForm] = useState(false);
  const [hideAppealButton, setHideAppealButton] = useState(false);
  const [showAppealPreviewDialog, setShowAppealPreviewDialog] = useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">All applications</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-2 d-flex flex-column flex-md-row justify-content-md-between ">
            <SearchBox
              placeholder="Search..."
              disabled={!candidateList || candidateList.length === 0}
              onSearch={setSearchCandidate}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="table-responsive">
            <table
              className={`table table-sm ${
                candidateList ? "table-striped" : ""
              }`}
            >
              <thead>
                <tr>
                  <th style={{ width: "14px", verticalAlign: "middle" }}>#</th>
                  <th>
                    <span className="d-flex flex-wrap align-items-center">
                      <span className="mr-2">
                        Candidates{" "}
                        {!!candidateList.length && (
                          <span className="text-primary">
                            ({candidateList.length})
                          </span>
                        )}
                      </span>

                      {!hideAppealButton &&
                      application.adStatusId === 1 &&
                      !!application.canAppeal &&
                      application.apStatusId === 2 ? (
                        <>
                          <span className="mr-2 mt-1">
                            <CountDownTimer
                              counterType="small"
                              defaultLeftDifferent={
                                application.appealLeftTime || 0
                              }
                              onTimeOut={() => setHideAppealButton(true)}
                            />
                          </span>
                          <Button
                            disabled={showAppealForm}
                            variant="contained"
                            size="small"
                            disableElevation
                            className="mt-1 mr-2"
                            onClick={() => {
                              setShowAppealForm(true);
                            }}
                            aria-label={`Appeal shortlist for ${
                              application.positionName
                            }${
                              application.entityName
                                ? " at " + application.entityName
                                : ""
                            }`}
                          >
                            <span aria-hidden="true">Appeal</span>
                          </Button>
                        </>
                      ) : application.apStatusId === 2 ? (
                        <span className="badge badge-pill text-danger">
                          Appeal period is over
                        </span>
                      ) : null}
                    </span>
                  </th>
                  <th
                    className="text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && !candidateList.length ? (
                  Array.from(Array(10).keys()).map((item, index) => (
                    <tr align="left" key={index}>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                    </tr>
                  ))
                ) : paginatedCandidates.length ? (
                  paginatedCandidates.map((candidate, index) => (
                    <tr
                      key={getNo(candidate.id, candidateList, "id")}
                      className={`${candidate.isMe ? "tr-bordered-blue" : ""}`}
                    >
                      <td className="text-right" aria-hidden="true">
                        {getNo(candidate.id, candidateList, "id") + 1}
                      </td>
                      <td className="text-left">
                        <span className="d-flex align-items-center">
                          {candidate.names}
                          {candidate.isMe && (
                            <span className="badge badge-primary ml-1">Me</span>
                          )}
                        </span>
                      </td>
                      <td
                        className={`text-right text-${
                          candidate.statusId === 1
                            ? "success"
                            : [2, 3].includes(candidate.statusId)
                            ? "danger"
                            : ""
                        }`}
                      >
                        {!!candidate.isMe &&
                          !!application.shortListAppealId && (
                            <span
                              onClick={() => {
                                setShowAppealPreviewDialog(true);
                              }}
                              className="badge badge-pill text-warning font-weight-bold text-uppercase text-underline cursor-pointer"
                            >
                              Appealed
                            </span>
                          )}
                        {candidate.statusName}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <NoResults />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* display pagination */}
          </div>
        </DialogContent>
        <DialogActions>
          {candidateList.length > itemsPerPage && (
            <div
              className="justify-content-center col-12 mb-3"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1 d-none d-md-inline text-blue-main">
                      Previous
                    </span>
                  </>
                }
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info text-blue-main btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info text-blue-main btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
        </DialogActions>
      </Dialog>

      {showAppealForm && (
        <AppealForm
          showDialog={showAppealForm}
          setShowDialog={setShowAppealForm}
          application={application}
          appealLevelId={1}
          appealTypeId={1}
          onClose={onClose}
        />
      )}

      {showAppealPreviewDialog && (
        <AppealPreviewDialog
          showDialog={showAppealPreviewDialog}
          setShowDialog={setShowAppealPreviewDialog}
          appealId={application.shortListAppealId}
          application={application}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, {})(ShowApplicantCandidates);
