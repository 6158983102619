import {
  Autocomplete,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import {
  getUserExperiences,
  deleteUserExperience,
  updateUserFile,
  requestWorkCertificate,
} from "../../../../store/profile/actions";
import loadingGif from "../../../assets/loading.gif";
import colors from "../../../utils/colors";
import NoResults from "../../../comm/NoResults";
// import DialogModel from "../../../common/components/DialogModel";

import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ExperienceFormDialog from "./ExperienceFormDialog";
import LockIcon from "@mui/icons-material/Lock";

import AlertDialog from "../../../comm/AlertDialog";
// import LoadingData from "../../../common/components/LoadingData";
import moment from "moment";
import PreviewPdfUrl from "../../../comm/PreviewPdfUrl";
import AdvertSkeleton from "../../../comm/AdvertSkeleton";
import SearchBox from "../../../comm/SearchBox";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

export const Experiences = (props) => {
  const {
    user,
    env,
    loading,
    onWait,
    userExperiences,
    getUserExperiences,
    deleteUserExperience,
    updateUserFile,
    requestWorkCertificate,
  } = props;

  useEffect(() => {
    getUserExperiences();
  }, []);

  // const handleCancel = () => {
  //   setNames("");
  //   setPhone("");
  //   setEmail("");
  //   setDescription("");
  //   setFormError(null);
  //   setJob("");
  //   setFromDate("");
  //   setEndDate("");
  //   setExperience(null);
  //   setUploadedFile(null);
  //   setIsPresent(false);
  //   setJobField(null);
  // };

  // const handleLoads = (action) => {
  //   if (jobFields === null || jobFields.length === 0) {
  //     loadJobFields(setLoadingData);
  //   }
  //   setFormAction(action);
  // };

  // const [viewDocument, setViewDocument] = useState(null);

  const [experienceForm, setExperienceForm] = useState({
    description: "",
    employerName: "",
    institutionEmail: "",
    institutionPhoneNumber: "",
    jobTitle: "",
    fromDate: null,
    toDate: null,
    isCurrentlyActive: false,
    // jobField: "",
    file: null,
  });

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showExperienceForm, setShowExperienceForm] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="experiences">
        <CardContent>
          {loading && !onWait && !userExperiences.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: colors.blueLogo }}
                >
                  Work experience
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  disabled={loading}
                  onClick={() => {
                    setShowExperienceForm(true);
                  }}
                  size="small"
                  aria-label="Add new experience"
                >
                  <span aria-hidden="true">
                    <span className="d-none d-md-inline mr-1">Add</span> new
                  </span>
                </Button>
              </div>

              {userExperiences.length === 0 ? (
                <NoResults />
              ) : (
                userExperiences.map((experience, index) => (
                  <ExperienceItemCard
                    key={experience.id}
                    experience={experience}
                    index={index}
                    isLastRow={index + 1 === userExperiences.length}
                    loading={loading}
                    deleteUserExperience={deleteUserExperience}
                    setSelectedExperience={setSelectedExperience}
                    setShowExperienceForm={setShowExperienceForm}
                    setLoadingDocument={setLoadingDocument}
                    requestWorkCertificate={requestWorkCertificate}
                    updateUserFile={updateUserFile}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showExperienceForm && (
        <ExperienceFormDialog
          onShow={showExperienceForm}
          selectedExperience={selectedExperience}
          experienceForm={experienceForm}
          setExperienceForm={setExperienceForm}
          onClose={() => {
            setExperienceForm({
              description: "",
              employerName: "",
              institutionEmail: "",
              institutionPhoneNumber: "",
              jobTitle: "",
              fromDate: null,
              toDate: null,
              isCurrentlyActive: false,
              file: null,
            });
            setShowExperienceForm(false);
            setSelectedExperience(null);
          }}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user, env, loading, onWait, userExperiences }) => ({
  user,
  env,
  loading,
  onWait,
  userExperiences,
});

const mapDispatchToProps = {
  getUserExperiences,
  deleteUserExperience,
  updateUserFile,
  requestWorkCertificate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Experiences);

const ExperienceItemCard = (props) => {
  const {
    loading,
    env,
    experience,
    deleteUserExperience,
    setSelectedExperience,
    setShowExperienceForm,
    setLoadingDocument,
    updateUserFile,
    requestWorkCertificate,
    isLastRow,
  } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isCurrentInPosition = (experience) => {
    return !!experience?.isFromPublicSector && experience.toDate === null
      ? true
      : false;
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card mt-2`}
        elevation={0}
        variant="outlined"
        sx={{
          backgroundColor: !!isCurrentInPosition(experience)
            ? `#cdeef8`
            : "#fff",
          border: "none",
        }}
      >
        <CardHeader
          // avatar={<span className="font-weight-bold text-primary"></span>}
          title={
            <Typography>
              <strong>{experience.positionName} </strong> at{" "}
              <span>{experience.institutionName}</span>
            </Typography>
          }
          subheader={
            <span>
              <small className="btn-link">
                {experience.fromDate ? (
                  <>
                    {moment(experience.fromDate).format("MMM-YYYY")} -{" "}
                    {experience.toDate !== null && experience.toDate.length > 0
                      ? moment(experience.toDate).format("MMM-YYYY")
                      : "Present"}
                  </>
                ) : (
                  experience.oldPeriod ?? "None"
                )}
                {!!isCurrentInPosition(experience) &&
                  experience.isActing === 1 && (
                    <strong
                      className="badge badge-primary text- ml-2"
                      style={{ fontSize: ".75rem", fontweight: "bold" }}
                    >
                      Currently Acting
                    </strong>
                  )}

                {!!isCurrentInPosition(experience) && !experience.isActing && (
                  <strong
                    className="badge badge-success text-uppercase ml-2"
                    style={{ fontSize: ".75rem", fontweight: "bold" }}
                  >
                    Currently {experience.statusName}
                  </strong>
                )}
              </small>

              <div className="mt-2" style={{ fontSize: "12px" }}>
                {!!experience.employeeGroupId && (
                  <>
                    <div className="mb-1">
                      <span
                        className={`mr-2 badge badge-${
                          experience.employeeGroupId < 3
                            ? "primary"
                            : "secondary"
                        }  text-uppercase`}
                        style={{ fontSize: ".75rem", fontweight: "bold" }}
                      >
                        {experience.employeeGroupName}
                      </span>
                      Level: {experience.levelName}.{experience.scaleName}
                    </div>
                  </>
                )}

                <PhoneRoundedIcon
                  className="text-secondary"
                  style={{ fontSize: "14px", marginTop: "0px" }}
                />
                <span className="font-weight-bold ml-1">
                  {experience.institutionPhone}
                </span>

                <MailRoundedIcon
                  className="text- ml-2"
                  style={{ fontSize: "14px", marginTop: "0px" }}
                />
                <a
                  href={`mailto:${experience.institutionEmail}`}
                  className="font-weight-bold ml-1 text-dark"
                >
                  {experience.institutionEmail}
                </a>
              </div>
            </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center pull-right"
                style={{ borderRadius: "4px" }}
              >
                <p>
                  <IconButton
                    // disableRipple
                    // className="ml-n2"
                    size="small"
                    onClick={() => {
                      if (experience.docReferenceId) {
                        setOnViewDocument(true);
                      } else {
                        if (!!experience.isFromPublicSector) {
                          requestWorkCertificate(
                            {
                              employeeId: experience.userId,
                              employeePositionId: experience.employeePositionId,
                            },
                            setOnViewDocument,
                            experience,
                            setLoadingDocument
                          );
                        } else {
                          updateUserFile(
                            experience,
                            "CHANGE_EXPERIENCE_FILE",
                            experience.userId +
                              "-" +
                              experience.institutionName,
                            setOnViewDocument,
                            setLoadingDocument
                          );
                        }
                      }
                    }}
                  >
                    <FilePresentIcon
                      // fontSize="large"
                      style={{
                        color: !!experience.docReferenceId
                          ? "#fe4066"
                          : "#c57c7cb8",
                      }}
                    />
                  </IconButton>
                </p>

                <IconButton
                  size="small"
                  // className="ml-n2"
                  onClick={handleOpenMenu}
                  aria-label="settings"
                  disabled={!!experience.isFromPublicSector}
                >
                  <Badge color="info">
                    <span className="material-icons">more_vert</span>
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 2,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 15,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <span>
                    <MenuItem
                      disabled={loading}
                      onClick={() => {
                        handleCloseMenu();
                        setSelectedExperience(experience);
                        setShowExperienceForm(true);
                      }}
                      className="text-dark font-weight-light"
                    >
                      <span className="material-icons mr-1">edit_note</span>
                      Edit
                    </MenuItem>
                  </span>

                  <span>
                    <MenuItem
                      disabled={loading}
                      onClick={() => {
                        handleCloseMenu();
                        setOnDelete(true);
                      }}
                      className="text-danger font-weight-light"
                    >
                      <span className="material-icons mr-1">remove</span> Remove
                    </MenuItem>
                  </span>
                </Menu>
              </div>
            </>
          }
        />
      </Card>
      {!isCurrentInPosition(experience) && !isLastRow && (
        <>
          {" "}
          <Divider variant="fullWidth" />
          <Divider variant="fullWidth" />
        </>
      )}

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserExperience(experience.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this experience?"
          disabled={loading}
        />
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Experience document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              experience.docReferenceId,
          }}
        />
      )}
    </>
  );
};
