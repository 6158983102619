import { defaultState } from "./state";
import {
  shuffle,
  shuffleQuestions,
  sortQuestionByReference,
} from "../../app/utils";

import TYPES from "./action-types";
import COM_TYPES from "../comm/action-types";
const types = { ...COM_TYPES, ...TYPES };

const examSession = (state = defaultState.examSession, action) => {
  switch (action.type) {
    case types.SET_EXAM_SESSION:
      return action.data;

    case types.LOGOUT:
      return defaultState.examSession;
    default:
      return state;
  }
};

const results = (state = defaultState.results, action) => {
  switch (action.type) {
    case types.SET_RESULTS:
      return action.data;

    case types.LOGOUT:
      return defaultState.results;
    default:
      return state;
  }
};

const questions = (state = defaultState.questions, action) => {
  switch (action.type) {
    case types.SET_QUETIONS: {
      const shuffledQuestions = sortQuestionByReference(
        shuffleQuestions(
          shuffle(
            action.data.filter(
              ({ isForWrittenSkillsTest }) => !isForWrittenSkillsTest
            )
          )
        ).sort((a, b) => a.marks - b.marks)
      );

      const writtenSkillsTest = action.data.find(
        ({ isForWrittenSkillsTest }) => !!isForWrittenSkillsTest
      );

      if (!!writtenSkillsTest) shuffledQuestions.push(writtenSkillsTest);

      return shuffledQuestions;
    }

    case types.LOGOUT:
      return defaultState.questions;
    default:
      return state;
  }
};

const currentQuestionNo = (state = defaultState.currentQuestionNo, action) => {
  switch (action.type) {
    case types.SET_CURRENT_QUETION:
      return action.data;

    case types.LOGOUT:
      return defaultState.currentQuestionNo;
    default:
      return state;
  }
};

const answers = (state = defaultState.answers, action) => {
  switch (action.type) {
    case types.SET_ANSWERS:
      return action.data;

    case types.ADD_OR_UPDATE_ANSWER: {
      const tmpState = [...state];

      const index = tmpState.findIndex(
        ({ examQuestionId, applicantExamSessionId }) =>
          +examQuestionId === +action.data.examQuestionId &&
          applicantExamSessionId === action.data.applicantExamSessionId
      );

      if (index >= 0) tmpState[index] = action.data;
      else tmpState.push(action.data);

      return tmpState;
    }

    case types.LOGOUT:
      return defaultState.answers;
    default:
      return state;
  }
};

export default {
  examSession,
  questions,
  currentQuestionNo,
  answers,
  results,
};
