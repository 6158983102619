import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAppeal,
  acceptAppealDecision,
} from "../../../store/applicant/actions";
import "react-quill/dist/quill.snow.css";
import loadingGif from "../../assets/loading.gif";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AppealForm from "./AppealForm";
import ConfirmationDialog from "../../comm/ConfirmationDialog";
import { isEmpty } from "lodash";

export const AppealPreviewDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    application,
    appealId,
    appeal,
    getAppeal,
    acceptAppealDecision,
  } = props;

  const [confirmAcceptAppealDecision, setConfirmAcceptAppealDecision] =
    useState(false);

  const onClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    getAppeal(appealId, onClose);
  }, []);

  const [showAppealForm, setShowAppealForm] = useState(false);

  return (
    <>
      <Dialog onClose={onClose} open={showDialog} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="overline" display="block">
            <span className="text-dark">
              {(appeal?.appealTypeNameL1 || "") + " Appeal"}
            </span>
          </Typography>
          <IconButton
            aria-label="close appeal preview"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons" aria-hidden="true">
              close
            </span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              minHeight: "55vh",
            }}
          >
            {loading && isEmpty(appeal) && (
              <div className="text-center mt-5">
                <img src={loadingGif} height="128" alt="loading" />
              </div>
            )}

            {(!loading || !isEmpty(appeal)) && (
              <div className="w-100 mt-0 float-start">
                <div className="d-flex flex-row justify-content-center mt-3">
                  <span className="badge rounded-pill badge-primary ml-3 fw-bold">
                    First level Appeal
                  </span>
                </div>

                <div className="d-flex flex-row justify-content-start mt-4 w-100">
                  <div className="w-100">
                    <div
                      className="card rounded-3 w-100"
                      style={{
                        background: "rgba(0,99,207,.08)",
                      }}
                    >
                      <div className="card-body">
                        <div>
                          <p className="small p-0 ms-2 mb-0 rounded-3">
                            {appeal.messageL1}
                          </p>

                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 text-right"
                            style={{
                              fontSize: "11px",
                            }}
                            aria-label={`Your appeal was created on ${moment(
                              appeal.createdOnL1
                            ).format("llll")}`}
                          >
                            <span aria-hidden="true">
                              {moment(appeal.createdOnL1).format("llll")}
                            </span>
                          </p>
                        </div>

                        <div className="w-100 mt-0 float-start">
                          {appeal && appeal.decisionL1 && (
                            <>
                              <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                <div
                                  className="card rounded-3 card-border-left w-100"
                                  style={{
                                    backgroundColor: "#f5f6f7",
                                  }}
                                >
                                  <div
                                    className="card-header border-bottom border-secondary"
                                    aria-label={`First level appeal at ${application.entityName}`}
                                  >
                                    <small
                                      className="small card-title text-primary fw-bold text-truncate"
                                      aria-hidden="true"
                                    >
                                      First Level Decision
                                    </small>
                                    <br />

                                    <small
                                      style={{
                                        fontSize: "11px",
                                      }}
                                      className="float-end w-100 fw-bold"
                                      aria-hidden="true"
                                    >
                                      {application.entityName}
                                    </small>
                                  </div>
                                  <div className="card-body">
                                    <div className="small p-0 ms-2 mb-0 rounded-3">
                                      <span
                                        className={`badge badge-pill mr-3 ${
                                          appeal.isAppealValidL1
                                            ? "badge-success"
                                            : "badge-danger"
                                        }`}
                                      >
                                        Your appeal is{" "}
                                        {!appeal.isAppealValidL1 && "not"} valid
                                      </span>
                                      <p className="pt-2">
                                        {appeal.decisionL1}
                                      </p>
                                      <div
                                        className="small mt-1 mb-0 rounded-3 text-primary text-right"
                                        aria-label={`Decision was made on ${moment(
                                          appeal.decisionOnL1
                                        ).format("llll")}`}
                                      >
                                        <span
                                          style={{
                                            fontSize: "11px",
                                          }}
                                          aria-hidden="true"
                                        >
                                          {moment(appeal.decisionOnL1).format(
                                            "llll"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mt-0">
                                      <div>
                                        <div className="mt-1 d-flex justify-content-center align-items-center flex-wrap">
                                          {appeal &&
                                            !appeal.appealIdL2 &&
                                            (!appeal.isAppealValidL1 ||
                                              appeal.typeId !== 1) &&
                                            appeal.statusIdL1 === 2 && (
                                              <>
                                                <button
                                                  disabled={loading}
                                                  onClick={() =>
                                                    setConfirmAcceptAppealDecision(
                                                      true
                                                    )
                                                  }
                                                  className="btn btn-success mt-1 mx-1 btn-sm text-capitalize"
                                                  aria-label="Accept appeal decision"
                                                >
                                                  <span aria-hidden="true">
                                                    I accept the decision
                                                  </span>
                                                </button>

                                                {!!appeal.canAppealToL2 && (
                                                  <button
                                                    disabled={loading}
                                                    onClick={() =>
                                                      setShowAppealForm(true)
                                                    }
                                                    className="btn mx-1 mt-1 btn-sm btn-primary text-capitalize"
                                                  >
                                                    Appeal to second level
                                                  </button>
                                                )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                      <div>
                                        <p className="small mt-3 mb-0 rounded-3 text-primary float-end">
                                          {appeal &&
                                            appeal.statusIdL1 === 3 &&
                                            !appeal.appealIdL2 && (
                                              <span
                                                className="badge rounded-pill badge-success mr-3 fw-bold"
                                                aria-label="You have accepted the appeal decision"
                                              >
                                                <span aria-hidden="true">
                                                  Appeal accepted
                                                </span>
                                              </span>
                                            )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {appeal && !appeal.decisionOnL1 && (
                            <div className="d-flex flex-row justify-content-start mt-3 ml-3 mr-3 w-100 float-start">
                              <div
                                className="card rounded-3 card-border-left w-100"
                                style={{
                                  backgroundColor: "#f5f6f7",
                                }}
                              >
                                <div className="card-body">
                                  <p className="small p-0 ms-2 mb-0 rounded-3 text-primary">
                                    <AccessTimeIcon /> Waiting for first level
                                    response!
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {appeal.appealIdL2 && (
                  <div className="w-100  mt-3 float-start">
                    <div className="d-flex flex-row justify-content-center mt-3">
                      <span className="badge rounded-pill badge-success ml-3 fw-bold">
                        Second level Appeal
                      </span>
                    </div>

                    <div className="d-flex flex-row justify-content-start mt-4  w-100 float-start">
                      <div className="w-100">
                        <div
                          className="card rounded-3"
                          style={{
                            background: "#d6f0e0",
                          }}
                        >
                          <div className="card-body">
                            <p className="small p-0 ms-2 mb-0 rounded-3">
                              {appeal.messageL2}
                            </p>

                            <div
                              className="small text-muted mb-0 rounded-3 mt-2 text-right"
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              {moment(appeal.createdOnL2).format("llll")}
                            </div>

                            <div className="w-100  mt-0 float-start">
                              {appeal.decisionL2 && (
                                <>
                                  <div className="d-flex flex-column justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                    <div
                                      className="card rounded-3 card-border-left w-100"
                                      style={{
                                        backgroundColor: "#f5f6f7",
                                      }}
                                    >
                                      <div className="card-header border-bottom border-secondary">
                                        <div className="row">
                                          <div className="col-8">
                                            <small className="small card-title text-primary fw-bold">
                                              Second Level Decision
                                            </small>
                                            <br />

                                            <small
                                              style={{
                                                fontSize: "11px",
                                              }}
                                              className="float-end w-100 fw-bold"
                                            >
                                              National Public Service Commission
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body">
                                        <div className="small p-0 ms-2 mb-0 rounded-3">
                                          <span
                                            className={`badge badge-pill mr-3 ${
                                              appeal.isAppealValidL2
                                                ? "badge-success"
                                                : "badge-danger"
                                            }`}
                                          >
                                            Your appeal is{" "}
                                            {!appeal.isAppealValidL2 && "not"}{" "}
                                            valid
                                          </span>
                                          <p className="pt-2">
                                            {appeal.decisionL2}
                                          </p>
                                        </div>

                                        <div className="small mt-1 mb-0 rounded-3 text-primary text-right ">
                                          <span
                                            style={{
                                              fontSize: "11px",
                                            }}
                                          >
                                            {moment(appeal.decisionOnL2).format(
                                              "llll"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    {appeal.canAppealBeResolved && (
                                      <div
                                        className="card rounded-3 card-border-left w-100 mt-2"
                                        style={{
                                          backgroundColor: "#f5f6f7",
                                        }}
                                      >
                                        <div className="card-header border-bottom border-secondary">
                                          <div className="row">
                                            <div className="col-8">
                                              <small className="small card-title text-primary fw-bold">
                                                Appeal resolution.
                                              </small>
                                              <br />

                                              <small
                                                style={{
                                                  fontSize: "11px",
                                                }}
                                                className="float-end w-100 fw-bold"
                                              >
                                                {application.entityName}
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                        {appeal.resolvedMessage &&
                                        appeal.resolvedOn ? (
                                          <div className="card-body">
                                            <div className="small p-0 ms-2 mb-0 rounded-3">
                                              <p className="pt-2">
                                                {appeal.resolvedMessage}
                                              </p>
                                            </div>

                                            <div className="small mt-1 mb-0 rounded-3 text-primary text-right ">
                                              <span
                                                style={{
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {moment(
                                                  appeal.resolvedOn
                                                ).format("llll")}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="card-body">
                                            <p className="small p-0 ms-2 mb-0 rounded-3 text-primary">
                                              <AccessTimeIcon /> Waiting for
                                              resolution!
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              {appeal && !appeal.decisionL2 && (
                                <div className="d-flex flex-row justify-content-start mt-3 ml-3 mr-3 w-100 float-start">
                                  <div
                                    className="card rounded-3 card-border-left w-100"
                                    style={{
                                      backgroundColor: "#f5f6f7",
                                    }}
                                  >
                                    <div className="card-body">
                                      <p className="small p-0 ms-2 mb-0 rounded-3 text-primary">
                                        <AccessTimeIcon /> Waiting for second
                                        level response!
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {confirmAcceptAppealDecision && (
        <ConfirmationDialog
          btnMessage="Accept"
          confirmationDialog={confirmAcceptAppealDecision}
          message={`Are you sure you want to accept the appeal decision?`}
          setConfirmationDialog={setConfirmAcceptAppealDecision}
          onYes={() => {
            acceptAppealDecision(appeal.appealIdL1, () =>
              setConfirmAcceptAppealDecision(false)
            );
          }}
        />
      )}

      {showAppealForm && (
        <AppealForm
          showDialog={showAppealForm}
          setShowDialog={setShowAppealForm}
          application={application}
          appealLevelId={2}
          appealTypeId={appeal?.typeId}
          appealIdL1={appeal?.appealIdL1 || null}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, user, appeal }) => ({
  loading,
  user,
  appeal,
});

export default connect(mapStateToProps, {
  getAppeal,
  acceptAppealDecision,
})(AppealPreviewDialog);
