const EnvMessage = (props) => {
  const { env } = props;

  return (
    <>
      <div
        className="text-center px-1 py-0 text-uppercase"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          zIndex: "99999",
          fontSize: "10px",
          backgroundColor: "#fff3cd",
          borderColor: "#fff3cd",
          color: "#721c24",
        }}
        aria-hidden="true"
      >
        {env}. environment
      </div>
    </>
  );
};

export default EnvMessage;
