import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  saveAnswer,
  submitExam,
  attemptToCheat,
  refleshExamSession,
} from "../../../../store/exam/actions";
import Calculator from "../../../comm/Calculator";
import AnswerItem from "./AnswerItem";
import QuestionList from "./QuestionList";
import { Alert, ButtonGroup, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import ExamFileViewer from "./ExamFileViewer";
import defaultProfile from "../../../assets/default-profile.jpg";
import { useRestrictEvents } from "./useRestrictEvents";
import ConfirmationDialog from "../../../comm/ConfirmationDialog";
import ActionDialog from "../../../comm/ActionDialog";
import CountdownOne from "react-countdown";
import CountdownTwo from "react-countdown";

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const DoExam = (props) => {
  const {
    user,
    loading,
    questions,
    examSession,
    answers,
    application,
    saveAnswer,
    submitExam,
    attemptToCheat,
    currentQuestionNo,
    setDisplayMarks,
    setIsFullScreen,
    refleshExamSession,
  } = props;

  const dispatch = useDispatch();

  useRestrictEvents();

  const [confirmSubmitExam, setConfirmSubmitExam] = useState(false);
  const [timeIsOver, setTimeIsOver] = useState(false);
  const [tabToShow, setTabToShow] = useState("question");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    document.title = "Examination Portal - Do Exam";
  }, []);

  useEffect(() => {
    window.addEventListener("blur", handleLeaveBrowser);
    window.addEventListener("fullscreenchange", handleLeaveFullscreen);
    window.addEventListener("beforeunload", handleReloadTab);

    dispatch({ type: "SET_CURRENT_QUETION", data: 1 });

    return () => {
      window.removeEventListener("blur", handleLeaveBrowser);
      window.removeEventListener("fullscreenchange", handleLeaveFullscreen);
      window.removeEventListener("beforeunload", handleReloadTab);
      exitFullScreen();
      dispatch({ type: "SET_QUETIONS", data: [] });
      dispatch({ type: "SET_EXAM_SESSION", data: null });
      dispatch({ type: "SET_ANSWERS", data: [] });
      dispatch({ type: "SET_CURRENT_QUETION", data: null });
    };
  }, []);

  const handleLeaveBrowser = () => {
    handleAllCheatingAttempt("TRY_TO_LEAVE_BROWSER");
  };

  const handleLeaveFullscreen = (e) => {
    if (!document.fullscreenElement) {
      handleAllCheatingAttempt("TRY_TO_MINIMIZE");
    } else setIsFullScreen(true);
  };

  const handleReloadTab = (e) => {
    e.preventDefault();
    handleAllCheatingAttempt("TRY_TO_RELOAD");

    return (e.returnValue = "Are you sure you want to exit?");
  };

  const handleAllCheatingAttempt = (eventType) => {
    attemptToCheat(examSession.id, eventType, setIsFullScreen);
  };

  const getAnswered = () =>
    answers.find(
      (item) =>
        !!item.answer &&
        +item.examQuestionId === +questions[currentQuestionNo - 1]?.id
    );

  const goToNextQuestion = (pageNumber) => {
    dispatch({ type: "SET_CURRENT_QUETION", data: pageNumber });
  };

  useEffect(() => {
    setAnswer(getAnswered()?.answer || "");
  }, [currentQuestionNo, questions, answers]);

  const exitFullScreen = () =>
    !!document.fullscreenElement && document.exitFullscreen();

  const onSubmit = () => {
    submitExam(examSession.id, setIsSubmitting, setDisplayMarks);
  };

  const counterOneRef = useRef();
  const startCounterOne = () => counterOneRef.current.start();
  const pauseCounterOne = () => counterOneRef.current.pause();

  const counterTwoRef = useRef();
  const startCounterTwo = () => counterTwoRef.current.start();
  const pauseCounterTwo = () => counterTwoRef.current.pause();

  useEffect(() => {
    if (+(examSession.timeLeftToEndWritten || 0) > 0) {
      pauseCounterTwo();
      startCounterOne();
    } else {
      pauseCounterOne();
      startCounterTwo();
    }

    if (
      +(examSession.timeLeftToEndWritten || 0) +
        (+(examSession.examAdditionalMinutes || 0) +
          +(examSession.applicantAdditionalMinutes || 0)) *
          1000 *
          60 -
        +(examSession.timeEndedFromEndOfWritten || 0) <=
      0
    ) {
      if (questions[currentQuestionNo - 1]?.isForWrittenSkillsTest)
        saveAnswer({
          examQuestionId: +questions[currentQuestionNo - 1]?.id,
          applicantExamSessionId: examSession.id,
          answer: answer,
          applicationId: application.id,
          isForWrittenSkillsTest:
            questions[currentQuestionNo - 1]?.isForWrittenSkillsTest,
        });

      setTimeIsOver(true);
    } else setTimeIsOver(false);
  }, [examSession]);

  const [isRefleshing, setIsRefleshing] = useState(false);

  useEffect(() => {
    const refleshSession = setInterval(() => {
      if (!isRefleshing)
        refleshExamSession(examSession.id, setIsRefleshing, () => {
          startCounterOne();
          startCounterTwo();
        });
    }, 60000);

    return () => {
      clearInterval(refleshSession);
    };
  }, [isRefleshing]);

  useEffect(() => {
    const autoSave = setInterval(() => {
      if (
        !(
          questions[currentQuestionNo - 1]?.question?.questionType.id === 1 &&
          questions[currentQuestionNo - 1]?.question?.multipleChoiceOptions
        ) &&
        !(
          loading ||
          !answer ||
          (getAnswered()?.answer || "") === answer ||
          confirmSubmitExam ||
          timeIsOver ||
          isSubmitting
        ) &&
        Math.abs(
          (answer || "").length - (getAnswered()?.answer || "").length
        ) >= 64
      )
        saveAnswer(
          {
            examQuestionId: +questions[currentQuestionNo - 1]?.id,
            applicantExamSessionId: examSession.id,
            answer: answer,
            applicationId: application.id,
            isForWrittenSkillsTest:
              questions[currentQuestionNo - 1]?.isForWrittenSkillsTest,
          },
          false
        );
    }, 1000);

    return () => {
      clearInterval(autoSave);
    };
  }, [currentQuestionNo, answer, answers]);

  return (
    <>
      <div className="row m-0 align-items-start justify-content-between">
        <div className="col mt-md-0">
          {/* header */}
          <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
            <h6
              className="font-weight-bold"
              aria-label={`Exam on ${application.positionName}`}
            >
              <span inert="true">{application.positionName}</span>
            </h6>
            <div className="d-flex flex-row align-items-center mb-2">
              <div
                style={{
                  borderBottom: "solid #078ece",
                  marginRight: "0px",
                  paddingRight: "14px",
                  width: "50%",
                }}
              >
                <span
                  style={{
                    display: "block",
                    fontSize: ".7rem",
                    fontWeight: 400,
                    color: "#0471a3",
                    minWidth: "96px",
                  }}
                >
                  Remaining time
                </span>

                <span
                  style={{
                    display: "block",
                    fontSize: "1.2rem",
                    padding: "4px 0",
                    fontWeight: 600,
                    color: "#0471a3",
                    marginTop: "-5px",
                  }}
                >
                  <CountdownOne
                    ref={counterOneRef}
                    controlled={false}
                    onComplete={() =>
                      !timeIsOver &&
                      (+examSession.examAdditionalMinutes +
                        +examSession.applicantAdditionalMinutes) *
                        1000 *
                        60 -
                        +examSession.timeEndedFromEndOfWritten <=
                        0 &&
                      !isRefleshing &&
                      refleshExamSession(
                        examSession.id,
                        setIsRefleshing,
                        startCounterTwo
                      )
                    }
                    daysInHours
                    date={Date.now() + +(examSession.timeLeftToEndWritten || 0)}
                  />
                </span>
              </div>

              <div
                style={{
                  borderBottom: "solid #078ece",
                  borderLeft: "solid #078ece",
                  marginLeft: "0px",
                  paddingLeft: "14px",
                  width: "50%",
                }}
              >
                <span
                  style={{
                    display: "block",
                    fontSize: ".7rem",
                    fontWeight: 400,
                    color: "#a52a2a",
                    minWidth: "96px",
                  }}
                >
                  Additional time
                </span>

                <span
                  style={{
                    display: "block",
                    fontSize: "1.2rem",
                    padding: "4px 0",
                    fontWeight: 600,
                    color: "#a52a2a",
                    marginTop: "-5px",
                  }}
                >
                  <CountdownTwo
                    onComplete={() =>
                      !timeIsOver &&
                      examSession.timeLeftToEndWritten <= 0 &&
                      !isRefleshing &&
                      refleshExamSession(
                        examSession.id,
                        setIsRefleshing,
                        startCounterTwo
                      )
                    }
                    ref={counterTwoRef}
                    controlled={false}
                    daysInHours
                    date={
                      Date.now() +
                      (+examSession.examAdditionalMinutes +
                        +examSession.applicantAdditionalMinutes) *
                        1000 *
                        60 -
                      +examSession.timeEndedFromEndOfWritten
                    }
                  />
                </span>
              </div>
            </div>

            <Button
              variant="contained"
              type="button"
              size="large"
              disableElevation
              color="success"
              className="rounded"
              disabled={timeIsOver || confirmSubmitExam}
              onClick={() => {
                setConfirmSubmitExam(true);
              }}
              aria-label="Click to submit exam"
            >
              <span inert="true">Finish Exam</span>
            </Button>
          </div>

          <div className="rounded elevated border bg-light mt-3">
            <div className="row p-3" style={{ height: "100%" }}>
              {!!examSession.examFile && (
                <div className="col-12 col-md-6" aria-label="Exam pdf file">
                  <ExamFileViewer fileUrl={examSession.examFile} />
                </div>
              )}
              <div className="col-12 col-md">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span
                    className="font-weight-bold"
                    style={{ fontSize: "13px" }}
                  >
                    Question {currentQuestionNo}{" "}
                    {questions[currentQuestionNo - 1]
                      ?.isForWrittenSkillsTest && (
                      <span className="badge badge-info p-2">
                        Writing skills test
                      </span>
                    )}
                  </span>
                  <span className="badge badge-primary p-1 ml-1">
                    {questions[currentQuestionNo - 1]?.marks} marks
                  </span>
                </div>
                <p className="mt-1">
                  {questions[currentQuestionNo - 1]?.question.description}
                </p>

                <div className="d-flex flex-column my-2 mt-3 border p-3 bg-white rounded">
                  <span
                    className="text-secondary"
                    aria-label="Answer options for the question"
                  >
                    <span inert="true">Answers</span>
                  </span>

                  {questions[currentQuestionNo - 1]?.question?.questionType
                    .id === 1 &&
                  questions[currentQuestionNo - 1]?.question
                    ?.multipleChoiceOptions ? (
                    <div className="d-flex flex-column">
                      {questions[
                        currentQuestionNo - 1
                      ].question.multipleChoiceOptions.map((option, index) => (
                        <span
                          key={`${currentQuestionNo || 0}-${option.id}`}
                          onClick={() =>
                            !(getAnswered()?.answer === option.optionNumber) &&
                            saveAnswer({
                              examQuestionId:
                                +questions[currentQuestionNo - 1]?.id,
                              applicantExamSessionId: examSession.id,
                              answer: option.optionNumber,
                              applicationId: application.id,
                            })
                          }
                          className={`btn mt-2`}
                          aria-label={`${option.description} ${
                            getAnswered()?.answer === option.optionNumber
                              ? "Selected"
                              : "Select"
                          }`}
                        >
                          <AnswerItem
                            selected={
                              getAnswered()?.answer === option.optionNumber
                            }
                            number={alphabets[index]}
                            title={option.description}
                            loading={loading}
                          />
                        </span>
                      ))}

                      <div className="d-flex flex-row justify-content-end mt-2">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="px-3"
                          disabled={loading}
                          onClick={() =>
                            goToNextQuestion(
                              questions.length === currentQuestionNo
                                ? 1
                                : currentQuestionNo + 1
                            )
                          }
                          aria-label="Next question"
                        >
                          <span inert="true">Next</span>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <TextField
                        autoFocus
                        label="Your answer"
                        placeholder="Your answer"
                        multiline
                        rows={13}
                        value={answer}
                        className="w-100 mt-2 bg-white"
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                      />
                      <div className="d-flex flex-row justify-content-end mt-2">
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          className="px-3 mr-2"
                          disabled={
                            loading ||
                            !answer ||
                            (getAnswered()?.answer || "") === answer ||
                            confirmSubmitExam ||
                            timeIsOver ||
                            isSubmitting
                          }
                          onClick={() => {
                            saveAnswer({
                              examQuestionId:
                                +questions[currentQuestionNo - 1]?.id,
                              applicantExamSessionId: examSession.id,
                              answer: answer,
                              applicationId: application.id,
                              isForWrittenSkillsTest:
                                questions[currentQuestionNo - 1]
                                  ?.isForWrittenSkillsTest,
                            });
                          }}
                          aria-label="Save your answer"
                          spellCheck="false"
                        >
                          <span inert="true">Save</span>
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="px-3"
                          disabled={
                            loading ||
                            confirmSubmitExam ||
                            timeIsOver ||
                            isSubmitting
                          }
                          onClick={() =>
                            goToNextQuestion(
                              questions.length === currentQuestionNo
                                ? 1
                                : currentQuestionNo + 1
                            )
                          }
                          aria-label="Next question"
                        >
                          <span inert="true">Next</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-xl-2">
          <div className="d-flex justify-content-center">
            {user && (
              <img
                src={
                  user?.profileImage
                    ? `data:image/png;base64,${user?.profileImage}`
                    : defaultProfile
                }
                className="img-fluid rounded img-thumbnail mr-1"
                style={{
                  display: "block",
                  height: "190px",
                }}
                alt={`Applicant ${user?.firstName} ${user?.lastName} profile`}
              />
            )}
          </div>

          {tabToShow === "question" && (
            <QuestionList
              // disabled={isExamTimeOut}
              goToNextQuestion={goToNextQuestion}
            />
          )}
          {tabToShow === "calculator" && (
            <div className="w-100 text-center mt-3 rounded elevated-1 px-3 py-4">
              <Calculator />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center mt-3">
            <ButtonGroup
              size="small"
              aria-label="buttons to control which one to display between questions and calculator"
              className="mb-2"
            >
              <Button
                key="questions"
                variant={"text"}
                disabled={tabToShow === "question"}
                onClick={() => setTabToShow("question")}
                aria-label={
                  tabToShow === "question"
                    ? "Already viewing questions number list"
                    : "Show questions number list"
                }
              >
                <span inert="true">Questions</span>
              </Button>
              <Button
                key="calculator"
                variant={"text"}
                disabled={tabToShow === "calculator"}
                onClick={() => setTabToShow("calculator")}
                aria-label={
                  tabToShow === "calculator"
                    ? "Already viewing calculator"
                    : "Show calculator"
                }
              >
                <span inert="true">Calculator</span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>

      {(confirmSubmitExam || timeIsOver) && !isSubmitting && (
        <ConfirmationDialog
          icon={timeIsOver ? "schedule" : undefined}
          error={timeIsOver}
          hideCloseBtn={timeIsOver}
          title={timeIsOver ? "Time Is Over" : undefined}
          color="success"
          btnMessage={`Submit answers`}
          confirmationDialog={
            (confirmSubmitExam || timeIsOver) && !isSubmitting
          }
          message={
            <div>
              {!timeIsOver && (
                <p>Are you sure you want to finish and submit this exam?</p>
              )}

              {answers.length < questions.length ? (
                <Alert
                  severity="error"
                  icon={false}
                  className="w-100"
                  aria-label={`You have only answered ${answers.length} out of ${questions.length}!`}
                >
                  <span inert="true">
                    You didn't answer all question? —{" "}
                    <strong>
                      {answers.length}/{questions.length}
                    </strong>
                  </span>
                </Alert>
              ) : (
                <Alert
                  severity="info"
                  icon={false}
                  className="w-100"
                  aria-label="You have answered all questions"
                >
                  <span inert="true">
                    You have answered all questions! —{" "}
                    <strong>
                      {answers.length}/{questions.length}
                    </strong>
                  </span>
                </Alert>
              )}
            </div>
          }
          setConfirmationDialog={setConfirmSubmitExam}
          onYes={onSubmit}
        />
      )}

      {isSubmitting && (
        <ActionDialog showAction={isSubmitting} action="Submitting..." />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  questions,
  currentQuestionNo,
  examSession,
  answers,
}) => ({
  user,
  questions,
  currentQuestionNo,
  examSession,
  answers,
  loading,
});

export default connect(mapStateToProps, {
  saveAnswer,
  submitExam,
  attemptToCheat,
  refleshExamSession,
})(DoExam);
